import React, { useState } from 'react';
import styled from 'styled-components';
import WaveButton from './WaveButton';
import { generateCallId } from '../utils/generateCallID';
import { Phone, Video } from 'react-feather';

const NewCallAlertModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  width: 400px;
  max-width: 90vw;
`;

const Header = styled.h2`
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #002C2C;
  margin-bottom: 20px;
  text-align: center;
`;

const UrlContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const UrlDisplay = styled.input`
  flex-grow: 1;
  padding: 8px 12px;
  border: 1px solid #D8EAD9;
  border-radius: 4px 0 0 4px;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
`;

const CopyButton = styled.button`
  background-color: #D8EAD9;
  border: 1px solid #B8D8BC;
  border-radius: 0 4px 4px 0;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, outline 0.3s ease-in-out;
  font-family: 'Inter', sans-serif;
  outline: none;

  &:hover {
    background-color: #B8D8BC;
  }

  &:focus {
    outline: 2px solid #002C2C;
    outline-offset: 2px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const CancelButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: rgba(0, 44, 44, 0.5); /* Semi-transparent version of #002C2C */
  border: none;
  padding: 4px 8px;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #555;
  }
`;

const BodyText = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #FF0000; /* Red color for alert message */
  margin-bottom: 16px;
  text-align: center;
`;


interface NewCallAlertProps {
  onClose: () => void;
}

const NewCallAlert: React.FC<NewCallAlertProps & { bodyText?: string, isVideoCall?: string }> = ({ onClose, bodyText, isVideoCall = true }) => {
  const [callId] = useState(generateCallId());
  const callUrl = `${window.location.origin}/demo/${callId}`

  const copyToClipboard = () => {
    navigator.clipboard.writeText(callUrl);
  };

  const joinCall = () => {
    window.open(callUrl, '_blank');
  };

  return (
    <NewCallAlertModal>
        <CancelButton onClick={onClose}>X</CancelButton>
      <Header>New Call Link Sent!</Header>
      {bodyText && <BodyText>{bodyText}</BodyText>}
      <UrlContainer>
        <UrlDisplay value={callUrl} readOnly />
        <CopyButton onClick={copyToClipboard}>Copy</CopyButton>
      </UrlContainer>
      <ButtonContainer>
        <WaveButton 
          onClick={joinCall} 
          label="Join Call" 
          icon={isVideoCall === 'video' ? <Video size={16} /> : <Phone size={16} />}
        />
      </ButtonContainer>
    </NewCallAlertModal>
  );
};

export default NewCallAlert;
