import React from 'react';
import styled from 'styled-components';
import { User } from './UserEntry';
import { X } from 'react-feather'; 
import { motion } from 'framer-motion';
import CreateCallLink from './CreateCallLink';
import ContactDangerZone from './ContactDangerZone';

const ContactDetailsContainer = styled(motion.div)`
  font-family: 'Inter', sans-serif;
  background-color: #002C2C;
  color: #D8EAD9;
  padding: 2rem;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  position: relative;
  min-width: 300px;

@media (max-width: 768px) {
    width: 100vw;
    height: 100vh;
    padding: 1rem;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: #D8EAD9;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Avatar = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #D4EBD8;
  color: #0E2B2C;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
`;

const UserName = styled.h1`
  font-size: 2rem;
  margin-bottom: 0.5rem;
  text-align: center;
`;

const UserEmail = styled.p`
  font-size: 1.2rem;
  color: #B8D8BC;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const ContactInfo = styled.div`
  margin-bottom: 2rem;
`;

const InfoItem = styled.p`
  margin-bottom: 0.5rem;
`;

interface ContactDetailsProps {
    user: User;
    onCall: () => void;
    onClose: () => void;
    onDelete: () => void;
  }

  const ContactDetails: React.FC<ContactDetailsProps> = ({ user, onClose, onDelete }) => {
      // If user is null, display a loading state or error message
    if (!user) {
        return (
        <ContactDetailsContainer
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        >
            <CloseButton onClick={onClose}>
            <X size={24} />
            </CloseButton>
            <UserName>User not found</UserName>
        </ContactDetailsContainer>
        );
    }

    const handleUserDeleted = () => {
        onClose();  // Close the ContactDetails component
        onDelete();
      };

  // Safely access user properties
    const displayName = user.displayName || 'Unknown User';
    const email = user.email || 'No email provided';
    const firstLetter = displayName.charAt(0).toUpperCase();
    const phoneNumber = user.phone ? user.phone.toString() : 'N/A';
    const userUID = user.uid ? user.uid : 'N/A';

    return (
      <ContactDetailsContainer
        initial={{ x: '100%' }}
        animate={{ x: 0 }}
        exit={{ x: '100%' }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        <CloseButton onClick={onClose}>
          <X size={24} />
        </CloseButton>
        <Avatar>{firstLetter}</Avatar>
        <UserName>{displayName}</UserName>
        <UserEmail>{email}</UserEmail>
        <ContactInfo>
         <InfoItem>Phone: {phoneNumber}</InfoItem>
         <InfoItem>Security Key Count: {user.securityKeysCount}</InfoItem>
         <InfoItem>Role: {user.role}</InfoItem>
         <InfoItem>UID: {user.uid}</InfoItem>
      </ContactInfo>
        <CreateCallLink />
        <ContactDangerZone userUID={userUID} onUserDeleted={handleUserDeleted} />
      </ContactDetailsContainer>
    );
  };

export default ContactDetails;
