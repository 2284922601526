import { useState } from 'react';
import { toast } from 'react-toastify';
import WaveServer from '../services/WaveServer';
import { ROLE_CLIENT } from '../constants/Roles';

interface InvitationSenderHook {
  invitationSent: boolean;
  sentToEmail: string;
  handleSendInvitation: (event: React.FormEvent) => Promise<void>;
  resetForm: () => void;
}

export const useInvitationSender = (
  email: string,
  firstName: string,
  lastName: string,
  role: string,
  setPhone: (value: string) => void,
  setFirstName: (value: string) => void,
  setLastName: (value: string) => void,
  setEmail: (value: string) => void,
  setRole: (value: string) => void
): InvitationSenderHook => {
  const [invitationSent, setInvitationSent] = useState(false);
  const [sentToEmail, setSentToEmail] = useState('');

  const handleSendInvitation = async (event: React.FormEvent) => {
    event.preventDefault();
    setInvitationSent(false);
    try {
      const response = await WaveServer.sendMagicLink(email, firstName, lastName, email, role);
      if (response.success) {
        toast.success(`Invitation sent successfully to ${email}!`, { toastId: 'invitation-success' });
        setInvitationSent(true);
        setSentToEmail(email);
        resetForm();
      } else {
        toast.error(`Failed to send invitation: ${response.message}`, { toastId: 'invitation-failed' });
      }
    } catch (error) {
      if (error instanceof Error) {
        toast.error(`Failed to send invitation: ${error.message}`, { toastId: 'invitation-error' });
      }
    }
  };

  const resetForm = () => {
    setPhone('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setRole(ROLE_CLIENT);
  };

  return {
    invitationSent,
    sentToEmail,
    handleSendInvitation,
    resetForm
  };
};

export default useInvitationSender;