import React, { useState } from 'react';
import styled from 'styled-components';
import UserList, { User } from './UserList';
import { Search, Plus } from 'react-feather'; // Make sure to install react-feather
import NewContact from './NewContact';
import UserEntry from './UserEntry';

const ContactBoxContainer = styled.div`
  font-family: 'Inter', sans-serif;
  background-color: #002C2C;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  max-width: 1000px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const SearchContainer = styled.div`
  position: relative;
  flex-grow: 1;
  margin-right: 16px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 8px 32px 8px 12px;
  border: 1px solid #D8EAD9;
  border-radius: 4px;
  background-color: #004C4C;
  color: #D8EAD9;
  font-size: 14px;

  &::placeholder {
    color: #B8D8BC;
  }
`;

const SearchIcon = styled(Search)`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: #B8D8BC;
`;

const AddButton = styled.button`
  background-color: #004C4C;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #D8EAD9;
`;

export interface ContactBoxProps {
  users: (User | null)[];
  showEmail?: boolean;
  isLoading?: boolean;
  onAddUser?: () => void;
  onSearch?: (query: string) => void;
  onUserSelect?: (user: User) => void; 
}

const ContactBox: React.FC<ContactBoxProps> = ({
    users,
    showEmail = true,
    isLoading = false,
    onAddUser,
    onSearch,
    onUserSelect, // Add this line
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const query = event.target.value;
      setSearchQuery(query);
      if (onSearch) {
        onSearch(query);
      }
    };
  
    const handleAddUser = () => {
      setIsModalOpen(true);
      if (onAddUser) onAddUser();
    };

    const handleUserClick = (user: User) => {
        if (onUserSelect) {
            onUserSelect(user);
        }
    };
  
    const filteredUsers = users.filter(user => 
        user && (
            user.displayName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (showEmail && user.email.toLowerCase().includes(searchQuery.toLowerCase()))
        )
    );

    return (
        <ContactBoxContainer>
            <Header>
                <SearchContainer>
                    <SearchInput
                        type="text"
                        placeholder="Search contacts..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <SearchIcon size={18} />
                </SearchContainer>
                <AddButton onClick={handleAddUser}>
                    <Plus size={18} />
                </AddButton>
            </Header>
           {isLoading && users.length === 0 ? (
                <>
                    <UserEntry user={null} isLoading={true} />
                    <UserEntry user={null} isLoading={true} />
                    <UserEntry user={null} isLoading={true} />
                </>
            ) : (
                <UserList 
                users={filteredUsers} 
                showEmail={showEmail} 
                isLoading={isLoading} 
                onUserClick={handleUserClick} 
            />
            )}
            {isModalOpen && <NewContact onClose={() => setIsModalOpen(false)} />}
        </ContactBoxContainer>
    );
};

export default ContactBox;