import React, { useMemo } from 'react';
import styled from 'styled-components';
import { User } from './UserEntry';
import { useUserSettings } from '../hooks/useUserSettings';
import UserProfile from './userProfile';
import JsonTokenContainer from './jsonTokenContainer';
import SecurityKeys from './securityKeys';

const SettingsContainerWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding: 1rem;
  background-color: #002C2C;
  color: #D8EAD9;
  height: 100%;
  overflow-y: auto;
  width: 100%;

  @media (max-width: 768px) {
    gap: 1rem;
    padding: 0.5rem;
  }
`;

const UserProfileRow = styled.div`
  grid-column: 1 / -1;
`;

const LeftColumn = styled.div`
  grid-column: 1 / 2;

  @media (max-width: 768px) {
    grid-column: 1 / -1;
  }
`;

const RightColumn = styled.div`
  grid-column: 2 / 3;

  @media (max-width: 768px) {
    grid-column: 1 / -1;
  }
`;

const SettingsContainer: React.FC = () => {
    const { securityKeys, decodedToken } = useUserSettings();

    const currentUser = useMemo((): User | null => {
        if (!decodedToken) return null;
        
        return {
          uid: decodedToken.user_id || '',
          displayName: decodedToken.name || '',
          email: decodedToken.email || '',
          phone: decodedToken.phone_number || 0,
          role: decodedToken.role || 'user',
          securityKeysCount: securityKeys.length,
        };
      }, [decodedToken, securityKeys]);
  
    return (
      <SettingsContainerWrapper>
        {currentUser && (
          <>
            <UserProfileRow>
              <UserProfile user={currentUser} />
            </UserProfileRow>
            <LeftColumn>
              <SecurityKeys keys={securityKeys} user = {currentUser} />
            </LeftColumn>
            <RightColumn>
              <JsonTokenContainer />
            </RightColumn>
          </>
        )}
      </SettingsContainerWrapper>
    );
  };
  
  export default SettingsContainer;