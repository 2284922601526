import { useState, useEffect } from 'react';
import { User } from "firebase/auth";
import { auth } from '../configuration/FirebaseConfig';
import { toast } from 'react-toastify';
import WaveServer from '../services/WaveServer';

export const useUserSettings = () => {
  const [user, setUser] = useState<User | null>(null);
  const [securityKeys, setSecurityKeys] = useState<any[]>([]);
  const [decodedToken, setDecodedToken] = useState<any>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        fetchSecurityKeys(user.uid);
        fetchAndDecodeToken();
      } else {
        setUser(null);
        setSecurityKeys([]);
        setDecodedToken(null);
      }
    });
  
    return () => unsubscribe();
  }, []);

  const fetchSecurityKeys = async (userId: string) => {
    try {
      const idToken = await auth.currentUser?.getIdToken();
      if (!idToken) {
        throw new Error('Failed to get ID token');
      }
      const credentials = await WaveServer.listCredentials(userId, idToken);
      setSecurityKeys(credentials);
    } catch (error) {
      console.error('Error fetching security keys:', error);
      toast.error('Failed to fetch security keys');
    }
  };

  const fetchAndDecodeToken = async () => {
    try {
      const idToken = await auth.currentUser?.getIdToken(true);
      if (idToken) {
        const decodedToken = JSON.parse(atob(idToken.split('.')[1]));
        setDecodedToken(decodedToken);
      } else {
        toast.error('No user is currently logged in');
      }
    } catch (error) {
      console.error('Error fetching or decoding token:', error);
      toast.error('Failed to fetch or decode token');
    }
  };

  const handleDeleteSecurityKey = async (credentialId: string) => {
    if (!user) return;

    if (securityKeys.length <= 1) {
      toast.error('Cannot delete the only security key. Add another key before deleting this one.', { toastId: 'last-key-error' });
      return;
    }

    try {
      const idToken = await auth.currentUser?.getIdToken();
      if (!idToken) {
        throw new Error('Failed to get ID token');
      }
      await WaveServer.deleteCredential(credentialId, user.uid, idToken);
      toast.success('Security key deleted successfully');
      fetchSecurityKeys(user.uid);
    } catch (error) {
      console.error('Error deleting security key:', error);
      toast.error('Failed to delete security key');
    }
  };

  return {
    user,
    securityKeys,
    decodedToken,
    fetchSecurityKeys,
    fetchAndDecodeToken,
    handleDeleteSecurityKey,
  };
};

export default useUserSettings;