/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
import { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../context/AuthProvider';
import * as Passwordless from '@passwordlessdev/passwordless-client';
import WaveServer from '../services/WaveServer';
import { PASSWORDLESS_API_KEY } from '../configuration/PasswordlessOptions';
import { toast } from 'react-toastify';
import { auth } from '../configuration/FirebaseConfig';
import { signInWithCustomToken } from "firebase/auth";
import LogRocket from 'logrocket';

export function Login() {
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { setUser } = useContext(AuthContext);

    const checkSupport = async () => {
        if (!Passwordless.isBrowserSupported()) {
            toast.error('Browser not supported.', { toastId: 'browser-not-supported' });
            return false;
        }
        const platformSupported = await Passwordless.isPlatformSupported();
        const autofillSupported = await Passwordless.isAutofillSupported();
        if (!platformSupported || !autofillSupported) {
            toast.error('Platform or Autofill not supported.', { toastId: 'platform-autofill-not-supported' });
            return false;
        } else {
            toast.success('Platform and Autofill supported.', { toastId: 'platform-autofill-supported' });
            return true;
        }
    };

    useEffect(() => {
        checkSupport();
    }, []);

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement> | React.FormEvent) => {
        e.preventDefault();
        setError(null);
        try {
            const passwordless_client = new Passwordless.Client({
                apiKey: PASSWORDLESS_API_KEY ?? ""
            });

            let token = null;
            let signinError = null;

            if (email) {
                const result = await passwordless_client.signinWithAlias(email);
                token = result.token;
                signinError = result.error;
            } else {
                const result = await passwordless_client.signinWithDiscoverable();
                token = result.token;
                signinError = result.error;
            }

            if (signinError) {
                throw new Error(typeof signinError === 'string' ? signinError : "Authentication failed");
            }

            if (!token) {
                throw new Error('No token received');
            }

            const resp_json = await WaveServer.signIn(token);
            
            if (resp_json.success) {
                const userCredential = await signInWithCustomToken(auth, resp_json.token);
                const user = userCredential.user;
                setUser(user);
                LogRocket.identify(user.uid, {
                    name: user.displayName ?? '',
                    email: user.email ?? '',
                });
                setSuccess(true);
                toast.success('Login successful!', { toastId: 'login-successful' });
            } else {
                throw new Error(resp_json.message || 'Login failed');
            }
        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : 'An unknown error occurred';
            console.error('Login error:', errorMessage);
            setError(errorMessage);
            toast.error(errorMessage, { toastId: 'login-error' });
        }
    };

    return {
        email,
        setEmail,
        success,
        checkSupport,
        handleSubmit,
        error
    };
}