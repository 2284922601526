import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown } from 'react-feather';
import useUserAssociation from '../hooks/useUserAssociation';
import useUserDeletion from '../hooks/useDeleteUser';
import useChangeUserRole from '../hooks/useChangeUserRole';

const DangerZoneContainer = styled.div<{ $expanded: boolean }>`
  margin-top: 2rem;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${props => props.$expanded ? '#ffecec' : 'transparent'};
  transition: background-color 0.3s ease;
`;

const DangerZoneHeader = styled.div<{ $expanded: boolean }>`
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: transparent;
  cursor: pointer;
  color: ${props => props.$expanded ? '#ff4d4d' : 'inherit'};
  transition: color 0.3s ease;
`;

const DangerZoneTitle = styled.h3`
  color: #ff4d4d;
  margin: 0;
  flex-grow: 1;
  font-family: 'Inter', sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
`;

const DangerZoneLine = styled.hr<{ $expanded: boolean }>`
  flex-grow: 1;
  border: none;
  border-top: 1px solid ${props => props.$expanded ? '#ff4d4d' : 'currentColor'};
  margin: 0 1rem;
  opacity: ${props => props.$expanded ? 1 : 0.2};
  transition: border-color 0.3s ease, opacity 0.3s ease;
`;

const ChevronIcon = styled(motion.div)<{ $expanded: boolean }>`
  color: ${props => props.$expanded ? '#ff4d4d' : 'inherit'};
  transition: color 0.3s ease;
`;

const DangerZoneContent = styled(motion.div)`
  background-color: #fff1f1;
  padding: 1rem;
`;

const DangerButton = styled.button`
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 1rem;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff3333;
  }

  &:active {
    background-color: #e60000;
  }
`;

const InlineContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const RoleSelect = styled.select`
  flex-grow: 1;
  padding: 0.5rem;
`;

const InlineButton = styled.button`
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  white-space: nowrap;
`;

const AssociationForm = styled.form`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const AssociationInput = styled.input`
  flex-grow: 1;
  padding: 0.5rem;
`;

const AssociationButton = styled(InlineButton)`
  flex-shrink: 0;
`;

interface ContactDangerZoneProps {
    userUID: string;
    onUserDeleted: () => void; 
  }
  
  const ContactDangerZone: React.FC<ContactDangerZoneProps> = ({ userUID, onUserDeleted }) => {
      const [expanded, setExpanded] = useState(false);
      const [newRole, setNewRole] = useState('');
      const [managerUID, setManagerUID] = useState('');
      //hooks
      const { isAssociating, handleAssociateUsers } = useUserAssociation();
      const { isDeleting, handleDeleteUser } = useUserDeletion();
      const { isChangingRole, changeUserRole } = useChangeUserRole();
  
      const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        handleAssociateUsers(managerUID, userUID);
      };

      const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
            const success = await handleDeleteUser(userUID);
          if (success) {
            onUserDeleted();
          }
        }
      };

      const handleChangeRole = async () => {
        console.log(newRole)
        if (newRole) {
          const success = await changeUserRole(userUID, newRole);
          if (success) {
            setNewRole('');
          }
        }
      };

    return (
      <DangerZoneContainer $expanded={expanded}>
        <DangerZoneHeader $expanded={expanded} onClick={() => setExpanded(!expanded)}>
          <DangerZoneTitle>Danger Zone</DangerZoneTitle>
          <DangerZoneLine $expanded={expanded} />
          <ChevronIcon
            $expanded={expanded}
            animate={{ rotate: expanded ? 180 : 0 }}
            transition={{ duration: 0.3 }}
          >
            <ChevronDown />
          </ChevronIcon>
        </DangerZoneHeader>
        <AnimatePresence>
          {expanded && (
            <DangerZoneContent
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
            <DangerButton onClick={handleDelete} disabled={isDeleting}>
              {isDeleting ? 'Deleting...' : 'Delete Account'}
            </DangerButton>
              <InlineContainer>
                <RoleSelect value={newRole} onChange={(e) => setNewRole(e.target.value)}>
                  <option value="">Change Role</option>
                  <option value="admin">Admin</option>
                  <option value="manager">Manager</option>
                  <option value="client">Client</option>
                </RoleSelect>
                <InlineButton onClick={handleChangeRole} disabled={isChangingRole || !newRole}>
                    {isChangingRole ? 'Changing...' : 'Apply'}
                </InlineButton>
              </InlineContainer>
              <AssociationForm onSubmit={handleSubmit}>
                <AssociationInput 
                  type="text" 
                  placeholder="Manager ID" 
                  value={managerUID}
                  onChange={(e) => setManagerUID(e.target.value)}
                  disabled={isAssociating}
                />
                <AssociationButton type="submit" disabled={isAssociating}>
                  {isAssociating ? 'Linking...' : 'Link to Manager'}
                </AssociationButton>
              </AssociationForm>
            </DangerZoneContent>
          )}
        </AnimatePresence>
      </DangerZoneContainer>
    );
  };

export default ContactDangerZone;
