import { useState, useCallback, useEffect } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { auth } from '../configuration/FirebaseConfig';
import WaveServer from '../services/WaveServer';

export interface Contact {
  id: string;
  name: string;
  email: string;
  role: string;
}

export const useContacts = (triggerFetch: boolean = false) => {
const [managers, setManagers] = useState<Contact[]>([]);
const [clients, setClients] = useState<Contact[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchContactDetails = useCallback(async (contactIds: string[]): Promise<Contact[]> => {
    try {
        const idToken = await auth.currentUser?.getIdToken();
        if (!idToken) {
          throw new Error('No ID token available');
        }
  
        const response = await WaveServer.getUsersInfo(contactIds, idToken);
        
        if (response.success && response.data) {
          if (Array.isArray(response.data)) {
            return response.data.map((user: any) => ({
              id: user.uid,
              name: user.displayName || 'Unknown',
              email: user.email || 'No email',
              role: user.role || 'No role'
            }));
          } else if (typeof response.data === 'object') {
            const user = response.data;
            return [{
              id: user.uid,
              name: user.displayName || 'Unknown',
              email: user.email || 'No email',
              role: user.role || 'No role'
            }];
          }
        }
        console.error('Error fetching user info');
        return [];
      } catch (error) {
        console.error('Error in fetchContactDetails');
        return [];
      }
    }, []);

    const fetchContacts = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
          const userId = auth.currentUser?.uid;
          if (!userId) {
            throw new Error('No user ID available');
          }
      
          const db = getFirestore();
          const userAssociationsRef = doc(db, 'userAssociations', userId);
          console.log('Attempting to fetch userAssociations document');
          const userAssociationsDoc = await getDoc(userAssociationsRef);
      
          if (!userAssociationsDoc.exists()) {
            setManagers([]);
            setClients([]);
            setError('No associations found for user');
            return;
          }
      
          const associations = userAssociationsDoc.data();
      
          const managerIds = associations.managers || [];
          const clientIds = associations.clients || [];
      
          const managersData = await fetchContactDetails(managerIds);
          const clientsData = await fetchContactDetails(clientIds);

          setManagers(managersData);
          setClients(clientsData);
      
          if (managersData.length === 0 && clientsData.length === 0) {
            setError('No contacts found');
          }
        } catch (error) {
          console.error('Error fetching contacts:', error);
          if (error instanceof Error) {
            setError(`Error fetching contacts: ${error.message}`);
            console.error('Error stack:', error.stack);
          } else {
            setError('Error fetching contacts. Please try again.');
          }
        } finally {
          setIsLoading(false);
        }
      }, [fetchContactDetails]);

      useEffect(() => {
        if (triggerFetch) {
          fetchContacts();
        }
      }, [triggerFetch, fetchContacts]);

  return { managers, clients, isLoading, error, fetchContacts };
};