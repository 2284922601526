/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
import React from 'react';

import { useCall, useCallStateHooks, CallingState, StreamTheme, CallControls, SpeakerLayout } from '@stream-io/video-react-sdk';
import { CallStatsComponent } from './CallStats';
import '@stream-io/video-react-sdk/dist/css/styles.css'
import './MyCallUI.css';
import { Card, Col, Container, Row, Button } from 'react-bootstrap';




export const MyCallUILayout = () => {
    const call = useCall();
  
    // Assume useCallStateHooks provides necessary hooks - adapt based on actual SDK
    const {
      useCallCallingState,
      useLocalParticipant,
      //useRemoteParticipants,
      useParticipants, // Added
      // ... other hooks
      //useScreenShareState,
    } = useCallStateHooks();
  
    const callingState = useCallCallingState();
    const localParticipant = useLocalParticipant();
    //const remoteParticipants = useRemoteParticipants();
    const participants = useParticipants();
    //const { screenShare, status } = useScreenShareState();
  
    //console.log('Calling state:', callingState);
  
    if (callingState === CallingState.LEFT) {
      // Remove the 'joined-call' class from the body if you leave the call
      document.body.classList.remove('joined-call');

      //when you leave the page reloads (i think thats what this does)
      return <Container>
            <Row>
                <br></br>
            </Row>
        <Row>
            <Col sm={2}></Col>
            <Col>
                <Card>
                    <Card.Body>
                        <Card.Title>demo.wavesign</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">That concludes the demo, thank you for your time!</Card.Subtitle>
                        <Button variant="outline-primary" onClick={() => window.location.reload()}>Make another call</Button>
                    </Card.Body>
                </Card>
            </Col>
        <Col sm={2}></Col>
        </Row>
      </Container>;
    } else   if (callingState !== CallingState.JOINED) {
      return <div>MyCallUILayout Loading...</div>;
    }

    console.log("Call: ", call?.cid)
    console.log("State: ", callingState)
    console.log("Participants: ", participants.length)
    console.log("Local Participant: ", localParticipant?.sessionId)
    //console.log('Screen sharing is:', status === 'enabled' ? 'active' : 'inactive');
  
    return (
      <>
      <StreamTheme>
        {/* <CallParticipantList participants={remoteParticipants} /> */}
        {/* The below are custom components, now going to use built in components
        <MyParticipantList participants={remoteParticipants} />
        <MyFloatingLocalParticipant participant={localParticipant} /> */}
        <SpeakerLayout participantsBarPosition='top'/>
        <CallControls />
        <CallStatsComponent />
      </StreamTheme>
      </>
    );
  };

  // export const MyParticipantList = (props: {
  //   participants: StreamVideoParticipant[];
  // }) => {
  //   const { participants } = props;
  //   return (
  //     <div
  //       style={{
  //         display: 'flex',
  //         flexDirection: 'row',
  //         gap: '8px',
  //         width: 'relative',
  //       }}
  //     >
  //       {participants.map((participant) => (
  //         <div style = {{ width: '100%', aspectRatio: '3 / 2'}}>
  //           <ParticipantView
  //             participant={participant}
  //             key={participant.sessionId}
  //             />
  //         </div>
  //       ))}
  //     </div>
  //   );
  // };
