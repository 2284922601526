/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import app, { auth } from '../configuration/FirebaseConfig'

interface AuthContextType {
  user: User | null;
  role: string | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  setRole: React.Dispatch<React.SetStateAction<string | null>>;
  logout: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({ 
  user: null, 
  role: null,
  setUser: () => {}, 
  setRole: () => {},
  logout: async () => {} // Provide a default logout function
}); 

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [currentRole, setCurrentRole] = useState<string | null>(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        setCurrentRole(idTokenResult.claims.role as string);
      } else {
        setCurrentRole(null);
      }
      setPending(false);
    });
  }, []);

  const logout = async () => {
    const auth = getAuth(app);
    try {
      await auth.signOut();
      setCurrentUser(null);
      setCurrentRole(null);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  if (pending) {
    return <>Loading...</>;
  }

  return (
    <AuthContext.Provider
      value={{
        user: currentUser,
        role: currentRole,
        setUser: setCurrentUser,
        setRole: setCurrentRole,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};