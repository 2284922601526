/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
import React from 'react';
import './CircleAnimation.css'

interface Props {
    show: boolean;
}

const CircleAnimation: React.FC<Props> = ({ show }) => {
    // Add your component logic here
    if (!show) {
        return null;
    }


    return (
        <div className = 'circleContainer'>
            <div className="circle" style={{ animationDelay: '.15s'}}></div>
            <div className="circle" style={{ animationDelay: '1.65s'}}></div>
            <div className="circle" style={{ animationDelay: '3.15s'}}></div>
            <div className="circle" style={{ animationDelay: '4.65s'}}></div>
        </div>
    );
};

export default CircleAnimation;