import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginWithMagicLink } from '../hooks/LoginWithMagicLink';
import { User, onAuthStateChanged } from 'firebase/auth';
import { toast } from 'react-toastify';
import { auth } from '../configuration/FirebaseConfig';
import { Spinner } from 'react-bootstrap';

export default function LoginFromLinkPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const [token, setToken] = useState<string | null>(null);
    const [user, setUser] = useState<User | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const { handleSubmit } = LoginWithMagicLink();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tokenParam = searchParams.get('token');

        if (tokenParam && tokenParam !== token) {
            setToken(tokenParam);
            (async () => {
                try {
                    setIsLoading(true);
                    await handleSubmit(tokenParam);
                } catch (error) {
                    console.error('Error during handleSubmit:', error);
                    toast.error('Authentication error: Unable to log in.', { toastId: 'handle-submit-error' });
                } finally {
                    setIsLoading(false);
                }
            })();
        } else if (!tokenParam) {
            console.warn('No token parameter found in URL');
            toast.error('Invalid login link. Please request a new one.', { toastId: 'invalid-link' });
            setIsLoading(false);
        }
    }, [location.search, token, handleSubmit]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
            setIsLoading(false);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (user && !isLoading) {
            navigate('/register/security-key');
        }
    }, [user, isLoading, navigate]);

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner animation="border">
                    <output className="visually-hidden">Loading...</output>
                </Spinner>
            </div>
        );
    }

    return (
        <div className="container mt-5">
            <h1>Login from Magic Link</h1>
            <p>Processing your login... If you're not redirected, please check your email for a new login link.</p>
        </div>
    );
}