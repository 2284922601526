/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
// from https://github.com/bitwarden/passwordless-react-example/blob/main/src/components/Menu.jsx

import { generateCallId } from '../utils/generateCallID';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Menue.module.css';
import { Nav, NavDropdown, Navbar, Container } from 'react-bootstrap';
import React, { useContext, useEffect, useState, useRef} from 'react';
import { AuthContext } from '../context/AuthProvider';

//for user info
import { onAuthStateChanged, User } from "firebase/auth";
import { auth } from '../configuration/FirebaseConfig';
import { ROLE_ADMIN } from '../constants/Roles';

function Menu() {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);
  const [user, setUser] = useState<User | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [expanded, setExpanded] = useState(false);
  const navbarRef = useRef<HTMLElement>(null); // Ref to the navbar container
  const timerRef = useRef<NodeJS.Timeout | null>(null); // Ref to store the timer

  useEffect(() => {

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        // Fetch the ID token to get the custom claims
        const idTokenResult = await user.getIdTokenResult();
        setUserRole(idTokenResult.claims.role as string | null);
      } else {
        setUser(null);
        setUserRole(null);
      }
    });

    // Function to handle click outside
    function handleClickOutside(event: MouseEvent) {
      if (navbarRef.current && !navbarRef.current.contains(event.target as Node)) {
        setExpanded(false);
      }
    }

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener('mousedown', handleClickOutside);
      if (timerRef.current) {
        clearTimeout(timerRef.current); // Clear the timer on cleanup
      }
    };
  }, [navbarRef]);

  useEffect(() => {
    // Set or clear the timer based on the `expanded` state
    if (expanded) {
      timerRef.current = setTimeout(() => {
        setExpanded(false);
      }, 5000); // Collapse after 5 seconds
    } else {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    }
  }, [expanded]);

  const handleDemoClick = () => {
    const callId = generateCallId();
    navigate(`/demo/${callId}`);
    setExpanded(false);
  };

  return (
    <Navbar ref={navbarRef} fixed='top' bg='dark' data-bs-theme='dark' expand='lg' className='navbar-custom' expanded={expanded}>
      <Container>
        <Navbar.Brand href="/">
          <img
            alt=""
            src="/img/WavesignLogo_Light_nbkg.png"
            width="50"
            className="d-inline-block align-top"
          />{' '}
           wavesign
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded => !expanded)} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {!user && <Nav.Link as={Link} to="/register" className="nav-link-text" onClick={() => setExpanded(false)}>Register</Nav.Link>}
            {!user && <Nav.Link as={Link} to="/login" className="nav-link-text" onClick={() => setExpanded(false)}>Login</Nav.Link>}
            {/* {user && <Nav.Link as={Link} to="/callpage" className="nav-link-text" onClick={() => setExpanded(false)}>Make a Call</Nav.Link>} */}
            {user && <Nav.Link onClick={handleDemoClick} className="nav-link-text">Demo</Nav.Link>}
            {user && <Nav.Link as={Link} to="/createcall" className="nav-link-text" onClick={() => setExpanded(false)}>Create Call</Nav.Link>}
            {user && userRole === ROLE_ADMIN && (
              <Nav.Link as={Link} to="/admin" className="nav-link-text" onClick={() => setExpanded(false)}>Manage Users</Nav.Link>
            )}
            {user && (
              <NavDropdown title={user ? user.displayName : 'User'} id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/user" onClick={() => setExpanded(false)}>Settings</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/" onClick={() => { logout(); setExpanded(false); }}>
                  <li>Logout</li>
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Menu;