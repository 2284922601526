import React, { ReactNode } from 'react';
import styled from 'styled-components';

const FormContainer = styled.div`
  font-family: 'Inter', sans-serif;
  padding: 8px;
  position: relative;
`;

const InputField = styled.input<{ $hasIcon: boolean }>`
  width: 100%;
  padding: 8px 12px;
padding-right: ${props => props.$hasIcon ? '40px' : '12px'};
  border: 1px solid #D8EAD9;
  border-radius: 4px;
  font-size: 14px;
  &::placeholder {
    color: rgba(128, 128, 128, 0.7);
  }
`;

const SelectField = styled.select<{ $hasIcon: boolean }>`
  width: 100%;
  padding: 8px 12px;
  padding-right: ${props => props.$hasIcon ? '80px' : '30px'}; // Increased right padding for icon and arrow
  border: 1px solid #D8EAD9;
  border-radius: 4px;
  font-size: 14px;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23888' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: ${props => props.$hasIcon ? 'right 40px center' : 'right 10px center'};
  background-size: 12px;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

export interface FieldFormProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  type: 'text' | 'dropdown';
  options?: { value: string; label: string }[];
  icon?: ReactNode;
}

const FieldForm: React.FC<FieldFormProps> = ({ value, onChange, placeholder, type, options, icon }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    onChange(event.target.value);
  };

  return (
    <FormContainer>
      {type === 'text' ? (
        <InputField
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          $hasIcon={!!icon}
        />
      ) : (
        <SelectField value={value || ''} onChange={handleChange} $hasIcon={!!icon}>
          {!value && <option value="" disabled>{placeholder}</option>}
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </SelectField>
      )}
      {icon && <IconContainer>{icon}</IconContainer>}
    </FormContainer>
  );
};

export default FieldForm;