import React, { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import useUserSettings from '../hooks/useUserSettings';
import { RefreshCw } from 'react-feather';

const Container = styled.div`
  margin-top: 1rem;
`;

const HeaderTitle = styled.h3`
  font-size: 1.2rem;
  color: #B8D8BC;
  margin-bottom: 0.5rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const JsonContainer = styled.pre`
  background-color: #003636;
  border: 1px solid #D8EAD9;
  border-radius: 4px;
  padding: 1rem;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: #D8EAD9;
`;

const RefreshButton = styled.button`
  background-color: #004C4C;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #D8EAD9;
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinningIcon = styled(RefreshCw)<{ $spinning: boolean }>`
  ${({ $spinning }) =>
    $spinning &&
    css`
      animation: ${spin} 1s linear infinite;
    `}
`;

interface JsonTokenContainerProps {
 //nothing here
}

const JsonTokenContainer: React.FC<JsonTokenContainerProps> = () => {
  const { decodedToken, fetchAndDecodeToken } = useUserSettings();
  const [isSpinning, setIsSpinning] = useState(false);

  const handleRefreshClick = async () => {
    setIsSpinning(true);
    //the promises make sure the animation spins for at least a second even if we fetch the code sooner
    const fetchPromise = fetchAndDecodeToken();
    const delayPromise = new Promise(resolve => setTimeout(resolve, 1000));
    await fetchPromise;
    await delayPromise;
    setIsSpinning(false);
  };

  return (
    <Container>
      <Header>
        <HeaderTitle>Registration Token</HeaderTitle>
        <RefreshButton onClick={handleRefreshClick}>
          <SpinningIcon size={18} $spinning={isSpinning} />
        </RefreshButton>
      </Header>
      <JsonContainer>
        {JSON.stringify(decodedToken, null, 2)}
      </JsonContainer>
    </Container>
  );
};

export default JsonTokenContainer;