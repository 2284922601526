/**
 * Redacts sensitive data from the provided data object or string.
 * This function is designed to ensure that sensitive information does not leak through logs or network requests.
 * It recursively scans objects and arrays, and redacts any fields that are deemed sensitive.
 * 
 * @param data - The data object or string from which sensitive information needs to be redacted.
 * @returns The data object or string with sensitive information redacted.
 */
export function redactSensitiveData(data: any): any {
    const redactedKeys = new Set(['token', 'session', 'refreshToken', 'idToken', 'clientDataJson', 'firebaseClient', 'firebaseClientToken', 'authenticatorData', 'signature', 'id', 'rawId']);
    
    if (typeof data === 'string') {
      try {
        // Parse if the data is a JSON string
        const jsonData = JSON.parse(data);
        return JSON.stringify(redactSensitiveData(jsonData));
      } catch (error) {
        // Return original data if parsing fails
        return data;
      }
    } else if (typeof data === 'object' && data !== null) {
      // Recursively redact sensitive data
      const newData: Record<string, any> = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          newData[key] = redactedKeys.has(key) ? '[REDACTED]' : redactSensitiveData(data[key]);
        }
      }
      return newData;
    }
    return data;
  }