/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const UnauthorizedPage: React.FC = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <Container>
            <Row className="justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Col xs={12} md={6} className="text-center">
                    <h1 className="text-danger mb-4">Unauthorized Access</h1>
                    <p className="mb-4">
                        You do not have permission to access this page. Please make sure you are logged in with the appropriate credentials.
                    </p>
                    <Button variant="primary" onClick={handleGoBack}>
                        Go Back
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}

export default UnauthorizedPage;