import { useState } from 'react';
import { toast } from 'react-toastify';
import WaveServer from '../services/WaveServer';
import { auth } from '../configuration/FirebaseConfig';

const useUserDeletion = () => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteUser = async (userUID: string) => {
    setIsDeleting(true);
    try {
      const idToken = await auth.currentUser?.getIdToken();
      if (!idToken) {
        throw new Error('No ID token available');
      }
      const result = await WaveServer.deleteUser(userUID, idToken);
      if (result.success) {
        toast.success('User deleted successfully');
        return true
      } else {
        toast.error(`Failed to delete user: ${result.message}`);
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error(error instanceof Error ? error.message : 'An unknown error occurred while deleting user');
    } finally {
      setIsDeleting(false);
    }
  };

  return { isDeleting, handleDeleteUser };
};

export default useUserDeletion;