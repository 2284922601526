import React from 'react';
import styled from 'styled-components';
import FieldForm from './FieldForm';
import WaveButton from './WaveButton';
import { useInvitationSender } from '../hooks/useInvitationSender';

const NewContactModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  width: 400px;
  max-width: 90vw;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Header = styled.h2`
font-family: 'Inter', sans-serif;
font-size: 18px;
font-weight: 600;
color: #002C2C;
margin-bottom: 10px;
text-align: center;
`;

const CancelButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: rgba(0, 44, 44, 0.5); /* Semi-transparent version of #002C2C */
  border: none;
  padding: 4px 8px;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #555;
  }
`;

interface NewContactProps {
    onClose: () => void;
  }

const NewContact: React.FC<NewContactProps> = ({ onClose }) => {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [role, setRole] = React.useState('');

  const {
    invitationSent,
    sentToEmail,
    handleSendInvitation,
  } = useInvitationSender(
    email,
    firstName,
    lastName,
    role,
    setPhoneNumber,
    setFirstName,
    setLastName,
    setEmail,
    setRole
  );

  const handleFirstNameChange = (value: string) => setFirstName(value);
  const handleLastNameChange = (value: string) => setLastName(value);
  const handleEmailChange = (value: string) => setEmail(value);
  const handlePhoneNumberChange = (value: string) => setPhoneNumber(value);
  const handleRoleChange = (value: string) => setRole(value);

  return (
    <NewContactModal>
      <CancelButton onClick={onClose}>X</CancelButton>
      <Header>Invite New User</Header>
      <form onSubmit={handleSendInvitation}>
        <FormGroup>
          <FieldForm value={firstName} onChange={handleFirstNameChange} placeholder="First Name" type="text" />
          <FieldForm value={lastName} onChange={handleLastNameChange} placeholder="Last Name" type="text" />
          <FieldForm value={email} onChange={handleEmailChange} placeholder="Email" type="text" />
          <FieldForm value={phoneNumber} onChange={handlePhoneNumberChange} placeholder="Phone Number" type="text" />
          <FieldForm 
            value={role} 
            onChange={handleRoleChange} 
            placeholder="Select Role" 
            type="dropdown" 
            options={[
              { value: 'client', label: 'Client' },
              { value: 'manager', label: 'Manager' },
              { value: 'admin', label: 'Admin' }
            ]} 
          />
        </FormGroup>
        <WaveButton 
          type="submit" 
          label="Invite via Email" 
          disabled={invitationSent} 
        />
      </form>
      {invitationSent && <div className="alert alert-success mt-3">Invitation has been sent successfully to {sentToEmail}!</div>}
    </NewContactModal>
  );
};

export default NewContact;
