/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Button, Row, Col, Modal } from 'react-bootstrap';
import { Login } from '../hooks/Login';

//to get the user details from auth context
import { onAuthStateChanged, User } from "firebase/auth";
import { auth } from '../configuration/FirebaseConfig';
import CircleAnimation from '../components/CircleAnimation';

//styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import JoinPage from './JoinPage';

const Demo: React.FC = () => {
    const { callID } = useParams<{ callID: string }>();
    const { success, handleSubmit } = Login();
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //to get the user details from auth context, this should probably be moved to a hook
    const [user, setUser] = useState<User | null>(null); // Create a state variable to store the user data

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                setUser(user); // Update the user state variable
            } else {
                // User is signed out
                setUser(null); // Clear the user state variable
            }
        });
    }, [])
    
    // useEffect(() => {
    //   //let result = checkSupport();
    // }, []);
  
    return (
      <>

        {success ? (
          <Container>
          <br></br>
          {user && <JoinPage contextUserId={user?.uid} ringCallId={callID}/>}
          </Container>
        ) : (
        <Container>
            <Row>
                <br></br>
            </Row>
            {!show && (
              <Button variant="outline-primary" onClick={handleShow}>
                  Restart Ringing
              </Button>
            )}
            
            {/* added kind of sketchy animation */}
            <CircleAnimation show={show}/>
            
            <Modal className = 'shake' show={show} onHide={handleClose} backdrop="static" centered>
                <Modal.Header closeButton>
                <Modal.Title>Incoming Call ...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Row>
                    <Col></Col>
                    <Col xs={9}>
                        <Row>
                            <Button variant="outline-success" onClick={handleSubmit} size="lg">
                                Answer
                            </Button>
                        </Row>
                        <br />
                        <Row>
                            <Button variant="outline-danger" onClick={handleClose} size="lg">
                                Decline
                            </Button>
                        </Row>
                    </Col>
                    <Col></Col>
                </Row>
                
                
                </Modal.Body>
                <Modal.Footer>
                    
                </Modal.Footer>
            </Modal>
        </Container>
        )}
      </>
    );
};

export default Demo;