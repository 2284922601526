/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
import React from 'react';
//for the router
import { Routes, Route } from 'react-router-dom';
import Demo from './pages/Demo';
import HomePage from './pages/external/HomePage';
import Layout from './components/Layout';
import LoginPage from './pages/LoginPage';
import LoginFromMagicLinkPage from './pages/LoginFromMagicLinkPage';
import RegistrationPage from './pages/RegistrationPage';
import RegisterSecurityKeyPage from './pages/RegsiterSecurityKeyPage';
import RequireAuth from './components/RequireAuth';
import UnauthorizedPage from './pages/UnauthorizedPage';
import RoleBasedRoute from './components/RoleBasedRoute';
import { ROLE_ADMIN, ROLE_MANAGER, ROLE_CLIENT } from './constants/Roles';
import 'react-toastify/dist/ReactToastify.css';
import './pages/App.css';

//brain transfer for new UI
import NewAdminPage from './new-ui/AdminPage';

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route element={<RequireAuth />}>
        <Route element={<RoleBasedRoute allowedRoles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_CLIENT]} />}>
          <Route path="/home" element={<NewAdminPage />} />
        </Route>
      </Route>
      
      <Route element={<Layout />}>
        <Route path="/register" element={<RegistrationPage />} />
        <Route path="/register/security-key" element={<RegisterSecurityKeyPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/login/magiclink" element={<LoginFromMagicLinkPage />} />
        <Route path="unauthorized" element={<UnauthorizedPage />} />
        <Route path="/demo/:callID" element={<Demo />} />
      </Route>
    </Routes>
  );
}

export default App;