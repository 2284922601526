/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
import { useState, useContext } from 'react';
import { AuthContext } from '../context/AuthProvider';
import WaveServer from '../services/WaveServer';
import { toast } from 'react-toastify';
import { auth } from '../configuration/FirebaseConfig';
import { signInWithCustomToken } from "firebase/auth";
import LogRocket from 'logrocket';

export function LoginWithMagicLink() {
    const [success, setSuccess] = useState(false);
    const { setUser } = useContext(AuthContext);

    const handleSubmit = async (token: string) => {
        if (!token) {
            toast.error('No token provided', { toastId: 'no-token-provided' });
            return;
        }

        try {
            const resp_json = await WaveServer.signIn(token);
            if (resp_json.error) {
                throw new Error("WaveServer error: " + resp_json.error);
            }
            
            if (resp_json.success) {
                const userCredential = await signInWithCustomToken(auth, resp_json.token);
                const user = userCredential.user;
                setUser(user);
                setSuccess(true);
                LogRocket.identify(user.uid, {
                    name: user.displayName ?? '',
                    email: user.email ?? '',
                });
                toast.success('Login successful!', { toastId: 'login-success' });
            } else {
                throw new Error('Login failed');
            }
        } catch (err) {
            console.error("Sign-in process error:", err);
            toast.error(`Sign-in error: ${(err as Error).message}`, { toastId: 'signin-error' });
            throw err; // Re-throw to be caught in the calling component
        }
    };

    return {
        success,
        handleSubmit
    };
}
