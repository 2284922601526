import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Card, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Passwordless from '@passwordlessdev/passwordless-client';
import { PASSWORDLESS_API_KEY } from '../configuration/PasswordlessOptions';
import { auth } from '../configuration/FirebaseConfig';
import { onAuthStateChanged, User } from 'firebase/auth';
import WaveServer from '../services/WaveServer';

const RegisterSecurityKeyPage = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState<User | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
            } else {
                navigate('/login');
            }
            setIsLoading(false);
        });

        return () => unsubscribe();
    }, [navigate]);

    const handleRegisterKey = async () => {
        setIsSubmitting(true);
        if (!user || !user.email) {
            toast.error('User information is missing.', { toastId: 'user-info-missing' });
            setIsSubmitting(false);
            return;
        }

        try {
            const idToken = await auth.currentUser?.getIdToken();
            if (!idToken) {
                throw new Error('Failed to get ID token');
            }

            const registerToken = await WaveServer.registerNewKey(user.uid, user.email, idToken);
            if (typeof registerToken !== 'string' || !registerToken.startsWith('register_')) {
                throw new Error('Invalid registration token received');
            }

            const passwordless_client = new Passwordless.Client({
                apiKey: PASSWORDLESS_API_KEY ?? '',
            });

            const finalResponse = await passwordless_client.register(registerToken, "first_one");
            if (!finalResponse) {
                throw new Error('Failed to register security key');
            }

            toast.success(`Security key registered successfully for ${user.email}!`, { toastId: 'registration-success' });
            navigate('/user');
        } catch (error: any) {
            toast.error(`Registration failed: ${error.message}`, {
                className: 'toast-error',
                toastId: 'registration-error'
            });
            console.error('Error during registration:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (isLoading) {
        return (
            <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Container>
        );
    }

    return (
        <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <Card style={{ width: '30rem' }}>
                <Card.Body>
                    <Card.Title>Register Your Security Key</Card.Title>
                    <Card.Text>
                        To complete your registration and ensure secure access, please register a new security key.
                    </Card.Text>
                    <Button 
                        variant="outline-primary" 
                        onClick={handleRegisterKey} 
                        disabled={isSubmitting} 
                        className="custom-button w-100"
                    >
                        {isSubmitting ? 'Registering...' : 'Register Security Key'}
                    </Button>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default RegisterSecurityKeyPage;
