import React from 'react';
import styled from 'styled-components';


export interface User {
  uid: string;
  phone: number;
  role: string;
  securityKeysCount: number;
  displayName: string;
  email: string;
}

export interface UserEntryProps {
  user: User | null; //Allow null for loading state
  showEmail?: boolean;
  $isSelected?: boolean; //needs to be transient prop
  onClick?: () => void;
  isLoading?: boolean;
  noToken?: boolean;
}

const UserItem = styled.div<{ $isSelected: boolean }>`
  padding: 12px 16px;
  border-bottom: 1px solid #0E2B2C;
  display: flex;
  align-items: center;
  background-color: ${({ $isSelected }) => ($isSelected ? '#004C4C' : 'transparent')};
  cursor: pointer;
  
  &:last-child {
    border-bottom: none;
  }
`;

const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #D4EBD8;
  color: #0E2B2C;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 12px;
`;

const UserInfo = styled.div`
  flex: 1;
  min-width: 0;
`;

const UserName = styled.div`
  font-weight: 600;
  color: #D8EAD9;
  // to prevent long usernames from messing up our formatting
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UserEmail = styled.div`
  color: #B8D8BC;
  font-size: 0.9em;
  // to prevent long emails from messing up our formatting
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LoadingPlaceholder = styled.div`
  padding: 12px 16px;
  background-color: #002C2C;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const LoadingAvatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #004C4C;
  margin-right: 12px;
`;

const LoadingText = styled.div`
  height: 16px;
  width: 150px;
  background-color: #004C4C;
  margin-bottom: 8px;
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 10%;
    height: 100%;
    background: linear-gradient(to right, #004C4C, #005A5A, #004C4C);
    animation: loadingAnimation 2.5s infinite;
  }
  @keyframes loadingAnimation {
    0% { left: -100%; width: 10%; }
    100% { left: 100%; width: 100%; }
  }
`;

//alert icon
const AlertIcon = styled.div`
  color: #FF0000;
  margin-left: auto;
  display: block; // Added to ensure the icon is displayed
  font-family: 'Inter', sans-serif; // Refactored to match the rest of the component
  font-size: 0.9em; // Refactored to match the rest of the component
  font-weight: 600; // Refactored to match the rest of the component
`;

const UserEntry: React.FC<UserEntryProps> = ({ 
    user, 
    showEmail = true, 
    $isSelected = false, 
    onClick,
    isLoading = false,
    noToken = false
  }) => {
    if (isLoading) {
      return (
        <LoadingPlaceholder>
          <LoadingAvatar />
          <div>
            <LoadingText />
            {showEmail && <LoadingText style={{ width: '120px' }} />}
          </div>
        </LoadingPlaceholder>
      );
    }
    // Safely access user properties
    const name = user?.displayName || 'Unknown';
    const email = user?.email || 'No email';
    const firstLetter = name.charAt(0).toUpperCase();
  
    return (
      <UserItem $isSelected={$isSelected} onClick={onClick}>
        <Avatar>{firstLetter}</Avatar>
        <UserInfo>
          <UserName>{name}</UserName>
          {showEmail && <UserEmail>{email}</UserEmail>}
        </UserInfo>
        {noToken && <AlertIcon>No RegisToken!</AlertIcon>}
      </UserItem>
    );
  };
  
  export default UserEntry;