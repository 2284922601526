import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import WaveServer from '../services/WaveServer';
import { auth } from '../configuration/FirebaseConfig';

export const useUserManagement = () => {
  const [users, setUsers] = useState<Array<any>>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [nextPageToken, setNextPageToken] = useState<string | undefined>(undefined);
  const [hasMoreUsers, setHasMoreUsers] = useState(true);

  const loadUsers = useCallback(async () => {
    if (isLoadingUsers || !hasMoreUsers) return;
    setIsLoadingUsers(true);
    try {
      const idToken = await auth.currentUser?.getIdToken();
      if (!idToken) throw new Error('No ID token available');
      
      const response = await WaveServer.getAllUsers(idToken, 30, nextPageToken);
      if (response.success) {
        const newUsers = response.users || [];
        if (newUsers.length === 0) {
          setHasMoreUsers(false);
        } else {
          setUsers(prevUsers => mergeUniqueUsers(prevUsers, newUsers));
          setNextPageToken(response.nextPageToken);
          setHasMoreUsers(!!response.nextPageToken);
        }
      } else {
        toast.error(`Failed to fetch users: ${response.message ?? 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      toast.error('Failed to fetch users');
    } finally {
      setIsLoadingUsers(false);
    }
  }, [isLoadingUsers, nextPageToken, hasMoreUsers]);

  const handleRefreshUsers = () => {
    setUsers([]);
    setNextPageToken(undefined);
    setHasMoreUsers(true);
    loadUsers();
  };

  return { users, isLoadingUsers, hasMoreUsers, loadUsers, handleRefreshUsers };
};

const mergeUniqueUsers = (prevUsers: any[], newUsers: any[]) => {
  const uniqueNewUsers = newUsers.filter(newUser => 
    !prevUsers.some(existingUser => existingUser.uid === newUser.uid)
  );
  return [...prevUsers, ...uniqueNewUsers];
};

export default useUserManagement;