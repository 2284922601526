import React, { useState } from 'react';
import styled from 'styled-components';
import { ChevronRight, ChevronLeft } from 'react-feather'; // Import icons
import { AuthContext } from '../context/AuthProvider';
import { useContext } from 'react';

const ToggleButton = styled.button`
  position: absolute;
  top: 10px;
  right: -25px;
  background-color: #f8f9fa;
  border: none;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;

  @media (min-width: 769px) {
    display: none;
  }
`;

const SidebarContainer = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: 10px;
  left: ${({ $isOpen }) => ($isOpen ? '0' : '-200px')};
  height: 100vh;
  width: 200px;
  background-color: #0E2B2C;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transition: left 0.3s ease-in-out;
  z-index: 1000;

  @media (min-width: 769px) {
    position: relative;
    left: 0;
    width: 150px;
    box-shadow: none;
    border-right: 1.5px solid #D4EBD8; // Light green border
    padding-right: 10px; // Add padding to the right side
  }
`;

const LogoContainer = styled.div`
  display: absolute;
  align-items: center;
  padding: .5rem;
  border-bottom: 1px solid rgba(216, 234, 217, 0.3); // Faded line below
`;

const LogoImage = styled.img`
  width: 50px;
  margin-right: 0.5rem;
`;

const LogoText = styled.span`
  font-size: .8rem;
  color: #D8EAD9;
  font-weight: bold;
`;

const SidebarOption = styled.div<{ $isSelected: boolean }>`
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  color: ${props => props.$isSelected ? '#0E2B2C' : '#D8EAD9'};
  background-color: ${props => props.$isSelected ? '#D4EBD8' : 'transparent'};
  &:hover {
    background-color: #D4EBD8;
    color: #0E2B2C;
  }
`;

const LogoutOption = styled.div`
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
  padding: 0.5rem 1rem;
  margin: 0 1rem;
  border-radius: 4px;
  color: #A0C0A4; // Faded color
  cursor: pointer;
  text-align: left;
  transition: color 0.3s ease;
  border-top: 1px solid rgba(216, 234, 217, 0.3); // Faded line above
  padding-top: 15px; // Add some space above the text

  &:hover {
    color: #D8EAD9;
  }

  @media (max-width: 768px) {
    bottom: 60px; // Adjust for mobile to avoid overlap with toggle button
  }
`;


interface SidebarProps {
  selectedOption: string;
  onOptionSelect: (option: string) => void;
  userRole: string;
}

const Sidebar: React.FC<SidebarProps> = ({ selectedOption, onOptionSelect, userRole }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { logout } = useContext(AuthContext);

    const toggleSidebar = () => setIsOpen(!isOpen);

  return (
    <SidebarContainer $isOpen={isOpen}>
      <ToggleButton onClick={toggleSidebar}>
        {isOpen ? <ChevronLeft /> : <ChevronRight />}
      </ToggleButton>
      <LogoContainer>
          <LogoImage src="/img/WavesignLogo_Light_nbkg.png" alt="Wavesign Logo" />
          <LogoText>Wavesign</LogoText>
      </LogoContainer>
      <br></br>
      {userRole === 'admin' && (
        <SidebarOption 
          $isSelected={selectedOption === 'All Users'} 
          onClick={() => onOptionSelect('All Users')}
        >
          All Contacts
        </SidebarOption>
      )}
      {userRole !== 'client' && (
        <SidebarOption 
          $isSelected={selectedOption === 'Users'} 
          onClick={() => onOptionSelect('Users')}
        >
          My Contacts
        </SidebarOption>
      )}
      <SidebarOption 
        $isSelected={selectedOption === 'Settings'} 
        onClick={() => onOptionSelect('Settings')}
      >
        Settings
      </SidebarOption>
      <SidebarOption 
        $isSelected={selectedOption === 'Reports'} 
        onClick={() => onOptionSelect('Reports')}
      >
        Reports
      </SidebarOption>
      <LogoutOption onClick={() => { logout(); onOptionSelect('Logout'); }}>
        Logout
      </LogoutOption>
    </SidebarContainer>
  );
};

export default Sidebar;
