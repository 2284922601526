/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
import { Route, Routes } from "react-router-dom";
import App from "../App";
import HomePage from "../pages/external/HomePage";
import LoginPage from "../pages/LoginPage";

const AppRoutes = () => {
    return (
      <Routes>
        <Route path="/*" element={<App />}>
          <Route index element={<LoginPage />} />
          <Route path="home" element={<HomePage />} />
        </Route>
      </Routes>
    );
  };

export default AppRoutes;