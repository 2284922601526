import React, { useState } from 'react';
import styled from 'styled-components';
import { Edit2, Plus, Trash2 } from 'react-feather';
import { useUserSettings } from "../hooks/useUserSettings";
import RegisterSecurityKey from '../components/RegisterSecurityKey';
import { toast } from 'react-toastify';

const Container = styled.div`
  margin-top: 1rem;
`;

const HeaderTitle = styled.h3`
  font-size: 1.2rem;
  color: #B8D8BC;
  margin-bottom: 0.5rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const KeyList = styled.div`
  background-color: #003636;
  border: 1px solid #D8EAD9;
  border-radius: 4px;
  overflow-x: auto;
`;

const KeyRow = styled.div`
  display: flex;
  padding: 0.75rem;
  border-bottom: 1px solid #D8EAD9;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }
`;

const KeyColumn = styled.div`
  flex: 1;
  color: #D8EAD9;
  padding: 0 0.5rem;
`;

const ActionColumn = styled.div`
  width: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #D8EAD9;
`;

const DeleteButton = styled.button`
  background-color: #ff4136;
  color: white;
  border: none;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
`;

const AddButton = styled.button`
  background-color: #004C4C;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #D8EAD9;
`;

interface SecurityKey {
id: string;
  nickname: string;
  device: string;
  country: string;
  createdAt: string;
  lastUsedAt: string;
}

interface User {
    uid: string;
    phone: number;
    role: string;
    securityKeysCount: number;
    displayName: string;
    email: string;
  }

export interface SecurityKeysProps {
  keys: SecurityKey[];
  user: User;
}


const SecurityKeys: React.FC<SecurityKeysProps> = ({ keys, user }) => {
    const [editingKey, setEditingKey] = useState<string | null>(null);
    const { handleDeleteSecurityKey, fetchSecurityKeys } = useUserSettings();
    const [showRegisterKey, setShowRegisterKey] = useState(false);


  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const now = new Date();
    const diffTime = Math.abs(now.getTime() - date.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 1) {
      return 'Yesterday';
    } else if (diffDays <= 7) {
      return `${diffDays} days ago`;
    } else {
      return date.toLocaleDateString('en-US', { 
        year: 'numeric', 
        month: 'short', 
        day: 'numeric' 
      });
    }
  };

  const handleDelete = (keyId: string) => {
    if (window.confirm("Are you sure you want to delete this key?")) {
      handleDeleteSecurityKey(keyId);
    }
  };

  const handleAddSecurityKey = () => {
    if (keys.length >= 10) {
      toast.error('You can have a maximum of 10 security keys.', { toastId: 'max-keys-reached' });
      return;
    }
    setShowRegisterKey(true);
  };

  const handleRegisterKeySuccess = () => {
    setShowRegisterKey(false);
    if (user) {
      fetchSecurityKeys(user.uid); // Refresh the list of security keys
    } else {
      console.error('User is null when trying to fetch security keys');
      toast.error('Failed to refresh security keys. Please try again.');
    }
  };

  return (
    <Container>
      <Header>
        <HeaderTitle>Security Keys</HeaderTitle>
      <AddButton onClick={handleAddSecurityKey}>
            <Plus size={18} />
        </AddButton>
    </Header>
      <KeyList>
        <KeyRow key={"header"}>
          <KeyColumn><strong>Name</strong></KeyColumn>
          <KeyColumn><strong>Device</strong></KeyColumn>
          <KeyColumn><strong>Last Used</strong></KeyColumn>
          <ActionColumn></ActionColumn>
        </KeyRow>
        {keys.map((key) => (
        <KeyRow key={key.nickname}>
          <KeyColumn>{key.nickname}</KeyColumn>
          <KeyColumn>{key.device}</KeyColumn>
          <KeyColumn>{formatDate(key.lastUsedAt)}</KeyColumn>
          <ActionColumn>
            <IconButton onClick={() => setEditingKey(editingKey === key.id ? null : key.id)}>
              <Edit2 size={16} />
            </IconButton>
            {editingKey === key.id && (
                <DeleteButton onClick={() => handleDelete(key.id)}>
                  <Trash2 size={16} />
                </DeleteButton>
              )}
          </ActionColumn>
        </KeyRow>
      ))}
      </KeyList>
      {showRegisterKey && (
        <RegisterSecurityKey
            userId={user.uid}
            userEmail={user.email || ''}
     onSuccess={handleRegisterKeySuccess}
    onCancel={() => setShowRegisterKey(false)}
  />
)}
    </Container>
  );
};

export default SecurityKeys;
