import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './HomePage.css';

const HomePage = () => {
  //stuff for the interest survey
  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);


  function handleFeatureHover() {
    const featureItems = document.querySelectorAll('.feature-item, .method-item');
  
    featureItems.forEach(item => {
      item.addEventListener('mousemove', (e: Event) => {
        const mouseEvent = e as MouseEvent;
        const rect = (item as HTMLElement).getBoundingClientRect();
        const x = mouseEvent.clientX - rect.left;
        const y = mouseEvent.clientY - rect.top;
  
        const gradientX = (x / rect.width) * 100;
        const gradientY = (y / rect.height) * 100;
  
        (item as HTMLElement).style.setProperty('--gradient-x', `${gradientX}%`);
        (item as HTMLElement).style.setProperty('--gradient-y', `${gradientY}%`);
      });
  
      item.addEventListener('mouseleave', () => {
        (item as HTMLElement).style.removeProperty('--gradient-x');
        (item as HTMLElement).style.removeProperty('--gradient-y');
      });
    });
  }

  const handleFaceIdTap = () => {
    const faceIdImage = document.getElementById('faceIdImage');
    if (faceIdImage) {
      const normalImage = faceIdImage.querySelector('.face-id-image');
      
      faceIdImage.addEventListener('mouseenter', () => {
        normalImage?.classList.add('hidden');
        // successImage?.classList.remove('hidden');
      });
      
      faceIdImage.addEventListener('mouseleave', () => {
        normalImage?.classList.remove('hidden');
        // successImage?.classList.add('hidden');
      });
      
      faceIdImage.addEventListener('touchstart', (e) => {
        e.preventDefault();
        normalImage?.classList.add('hidden');
        // successImage?.classList.remove('hidden');
      });
      
      faceIdImage.addEventListener('touchend', () => {
        normalImage?.classList.remove('hidden');
        // successImage?.classList.add('hidden');
      });
    }
  };

  const handleFeatureScroll = () => {
    // Check if the device is mobile (you can adjust the width as needed)
  const isMobile = window.innerWidth <= 768;

    if (!isMobile) {
      // console.log('Not on mobile, skipping scroll animations');
      return;
    }


    const featureItems = document.querySelectorAll('.feature-item');
    const faceIdImage = document.getElementById('faceIdImage_pending');
    // console.log('Feature items found:', featureItems.length);
    // console.log('FaceIdImage found:', faceIdImage);
  
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        // console.log('Entry:', entry.target);
        // console.log('Intersection ratio:', entry.intersectionRatio);
        // console.log('Is intersecting:', entry.isIntersecting);
        // console.log('Bounding client rect:', entry.boundingClientRect);
  
        const elementTop = entry.boundingClientRect.top;
        const viewportHeight = window.innerHeight;
        
        // Calculate the percentage of the viewport the element has passed
        const scrollPercentage = (1 - (elementTop / viewportHeight)) * 100;
        // console.log('Scroll percentage:', scrollPercentage);
        
        if (entry.target.classList.contains('feature-item')) {
          if (entry.isIntersecting && scrollPercentage >= 25) {
            // console.log('Adding animate class');
            entry.target.classList.add('animate');
          } else {
            // console.log('Removing animate class');
            entry.target.classList.remove('animate');
          }
        }
  
        // Handle faceIdImage visibility
        if (faceIdImage && entry.target === faceIdImage) {
          if (entry.isIntersecting && scrollPercentage >= 60) {
            // console.log('Hiding faceIdImage');
            faceIdImage.classList.add('hidden');
          } else {
            // console.log('Showing faceIdImage');
            faceIdImage.classList.remove('hidden');
          }
        }
      });
    }, {
      threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      rootMargin: "0px 0px 0px 0px"
    });
  
    featureItems.forEach(item => {
      observer.observe(item);
    });
  
    // Observe faceIdImage separately
    if (faceIdImage) {
      observer.observe(faceIdImage);
    }
  };

  React.useEffect(() => {
    handleFeatureHover();
    handleFaceIdTap();
    handleFeatureScroll();
  
    const handleResize = () => {
      handleFeatureScroll();
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="home-page">
      <div className="banner">
        <div className="logo-container">
          <img src="/img/WavesignLogo_Light_nbkg.png" alt="Wavesign Logo" className="banner-logo" />
          <h2 className="banner-title">wavesign</h2>
        </div>
      <div className="banner-link">
        <a href="/login" className="login-link">Login</a>
      </div>
      </div>

      <header className="hero">
        <div className="container">
          <h1>Confirm identity over the phone in <span className="highlight">one second</span> or less.</h1>
          <p>Never ask your clients a security question or send an OTP code again. <br />
          <strong>Wavesign improves your client experience</strong> and <em>increases your security</em> against conventional and AI fraud.</p>
          <button className="cta-button" onClick={handleModalShow}>Get Started</button>
        </div>
        <div className="scroll-arrow" onClick={() => document.querySelector('.features')?.scrollIntoView({ behavior: 'smooth' })}></div>
      </header>

      <section className="features">
        <div className="container">
          <h2 className="section-title">Stop compromising between security and client experience.</h2>
          <div className="feature-grid">
            <div className="feature-item">
              <h3><span className="feature-prefix">wavesign is</span> <span className="feature-highlight">Fast.</span></h3>
              <p>Authenticate in less than 2 seconds, without any user input beyond unlocking their phone.</p>
            </div>
            <div className="feature-item">
              <h3><span className="feature-prefix">wavesign is</span> <span className="feature-highlight">Simple.</span></h3>
              <p>No more OTP codes or security questions. Just answer the call and you're authenticated.</p>
            </div>
            <div className="feature-item">
                <h3><span className="feature-prefix">wavesign is</span> <span className="feature-highlight">Secure.</span></h3>
                <p>Our product uses FIDO2 security standards, the most secure authentication method on the market.</p>
                <a href="https://fidoalliance.org/" target="_blank" rel="noopener noreferrer">
                  <img src="/img/FIDO_logo_black_RGB.png" alt="FIDO2 Logo" className="fido-logo" />
                </a>
            </div>
          </div>
        </div>
      </section>

      <section className="how-it-works">
        <div className="frosted-glass">
          <div className="container">
            <h2 className="section-title">We handle the authentication, all you need to do is pick up</h2>
            <div className="steps">
              
              <div className="step">
                <div className="step-content">
                  <div className="step-number">1</div>
                    <p>You get a call via the wavesign application</p>
                </div>
                <div className="step-image">
                    <img src="img/wireframes/Incoming_Call.png" alt="Incoming Call" />
                </div>
              </div>

              <div className="step">
                <div className="step-content">
                  <div className="step-number">2</div>
                  <p>Answer just like you unlock your device</p>
                </div>
                <div className="step-image" id="faceIdImage">
                    <img src="img/wireframes/Face_ID.png" alt="Face ID" className="face-id-image" id="faceIdImage_pending"/>
                    <img src="img/wireframes/Face_ID_success.png" alt="Face ID Success" className="face-id-success-image" />
                </div>
              </div>

              <div className="step">
                <div className="step-content">
                  <div className="step-number">3</div>
                  <p>Start your call with confidence</p>
                </div>
                <div className="step-image">
                    <img src="img/wireframes/Answered_Call.png" alt="Answered Call" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

<section className="current-methods">
<div className="container">
  <h2 className="section-title">Your current authentication methods aren't up to the challenge</h2>
  <div className="method-grid">
  <div className="method-item">
    <div className="method-content">
      <h3>Voice Recognition</h3>
      <p className="method-subheader">"Hey, it's me"</p>
      <div className="method-description">
        <p>Everyone performs this type of authentication everyday, likely without realizing it. Modern software can also recognize voices. <strong>The problem? GenAI voice clones can trick both.</strong></p>
      </div>
    </div>
    <div className="risks-grid">
      <div className="risk-item">Social engineering</div>
      <div className="risk-item">AI voice clones</div>
      <div className="risk-item">SIM swaps</div>
      <div className="risk-item">Impersonators</div>
    </div>
  </div>
  <div className="method-item">
    <div className="method-content">
      <h3>Security Questions</h3>
      <p className="method-subheader">"What's your dog's favorite food?"</p>
      <div className="method-description">
        <p>This method is <strong>extremely vulnerable to fraud attacks.</strong> It's also a notably bad user experience as many users struggle to remember the answers to these questions.</p>
      </div>
    </div>
    <div className="risks-grid">
      <div className="risk-item">Social engineering</div>
      <div className="risk-item">AI voice clones</div>
      <div className="risk-item">Data breaches</div>
      <div className="risk-item">Weak questions</div>
    </div>
  </div>
  <div className="method-item">
    <div className="method-content">
      <h3>One-time Password (OTP)</h3>
      <p className="method-subheader">"Please check your texts"</p>
      <div className="method-description">
        <p>The current state "gold standard" for authentication is clunky, time-intensive, expensive, and easily exploited. Here's a scary stat: <strong>80% of SIM-swap attacks are successful.</strong></p>
      </div>
    </div>
    <div className="risks-grid">
      <div className="risk-item">SIM swaps</div>
      <div className="risk-item">AI voice clones</div>
      <div className="risk-item">Phishing attacks</div>
      <div className="risk-item">Interception</div>
    </div>
  </div>
</div>
</div>
</section>

      <section className="testimonials">
        <div className="container">
          <div className="testimonial-grid">
            <div className="testimonial-item">
              <p>"My clients are ultra-high net worth individuals, for them AI voice clones aren't a future problem, they're happening right now. Wavesign is just what we needed to stop these attacks"</p>
              <cite>- CFO, Wealth Management Firm</cite>
            </div>
            <div className="testimonial-item">
              <p>"Ok when do I authenticate?"</p>
              <p>(He'd already authenticated)</p>
              <cite>- Mike O., Wealth Manager</cite>
            </div>
          </div>
        </div>
      </section>

      <section className="cta">
        <div className="container">
          <h2>Ready to Revolutionize Your Authentication?</h2>
          <p>Join us in building the world's most trusted inter-organization communication network.</p>
          <button className="cta-button" onClick={handleModalShow}>Request a Demo</button>
        </div>
      </section>

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body style={{ position: 'relative', paddingBottom: '120%', height: 0, overflow: 'hidden' }}>
          <iframe 
            title="Survey Form" 
            src="https://docs.google.com/forms/d/e/1FAIpQLSeBuxoR0wULgq-tyjBH0muY1-ypK4PAdU4DNn4WznSQmiL3ng/viewform?embedded=true" 
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 0 }}
          >
            Loading…
          </iframe>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default HomePage;
