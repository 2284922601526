// Array of Phish song titles to use for generating call IDs
const phishSongs = [
    'Tweezer', 'YEM', 'Chalk-Dust', 'Divided-Sky', 'Reba',
    'Fluffhead', 'Stash', 'Maze', 'Cavern', 'Possum',
    'Antelope', 'Mike-Song', 'Weekapaug', 'Bowie', 'Hood',
    'Lizards', 'Farmhouse', 'Bathtub-Gin',
    'Carini', 'Ghost', 'Piper', 'Sand', 'Twist',
    'Moma-Dance', 'Tube', 'Free', 'Wolfman', 'Julius',
    'Golgi', 'Llama', 'Mango', 'Fuego', 'Simple',
    'Wilson', 'Bouncing', 'Character-Zero', 'Sample',
    'Halley-Comet', 'Rift', 'Foam', 'Sparkle', 'Punch-You',
    'Runaway-Jim', 'Suzy-Greenberg', 'Waste', 'Blaze-On',
    'Axilla', 'Timber', 'Wingsuit', 'Scent-of-a-Mule',
    'Lawn-Boy', 'Ocelot', 'Stealing-Time', 'Steam'
  ];

//function to generate a callID automatically

export function generateCallId(): string {
    const getRandomSong = () => phishSongs[Math.floor(Math.random() * phishSongs.length)];
    const song1 = getRandomSong();
    const randomNumber = Math.floor(Math.random() * 1000).toString().padStart(3, '0');

    return `${song1}-${randomNumber}`;
  }