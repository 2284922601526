/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
// clientAndTokenProvider.ts
import { StreamVideoClient, User } from '@stream-io/video-react-sdk';
import WaveServer from '../services/WaveServer';
import { auth } from '../configuration/FirebaseConfig';

export const tokenProvider = (getstream_user: { id: string; name: string; image: string; }) => {
    if (auth.currentUser) {
        return auth.currentUser.getIdToken(true)
            .then((idToken) => {
                // Send token to your backend via HTTPS
                return WaveServer.token(getstream_user, idToken)
                    .then(result => {
                        console.log("returned the reult:", result);
                        return result;
                    });
            });
    } else {
        return Promise.reject("No user logged in");
    }
};

// Define an async factory method
export const createClient = async (apiKey: string, user: User, token: string) => {
  const client = new StreamVideoClient({
    apiKey,
    user,
    token,
  });

  await client.connectUser(user, token);
  return client;
}