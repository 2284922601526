/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { CallStats } from '@stream-io/video-react-sdk';
import './CallStats.css';
import { Button } from 'react-bootstrap';

export const CallStatsComponent: React.FC = () => {
  const [isMinimized, setIsMinimized] = useState(true);

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  return (
    <Draggable>
      <div className={`floating-call-stats ${isMinimized ? 'minimized' : ''}`}>
        <Button variant='outline-primary' onClick={toggleMinimize}>
          {isMinimized ? 'CallStats' : 'Minimize'}
        </Button>
        {!isMinimized && <CallStats />}
      </div>
    </Draggable>
  );
};
