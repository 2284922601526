import React, { useEffect, useState } from 'react';
import { User } from './UserList';
import styled from 'styled-components';
import ContactBox from './ContactBox';
import ContactDetails from './ContactDetails';
import Sidebar from './Sidebar';
import { AnimatePresence, motion } from 'framer-motion';
//brain transfer
import { useUserManagement } from '../hooks/useUserManagement';
import SettingsContainer from './SettingsContainer';
import useUserSettings from '../hooks/useUserSettings';
import { useContacts } from '../hooks/useContacts';

const AdminPageContainer = styled.div`
  font-family: 'Inter', sans-serif;
  display: flex;
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem;
  background-color: #002C2C;
  color: #D8EAD9;
  min-height: 100vh;
  overflow-x: hidden;

  @media (min-width: 769px) {
    max-width: 1200px;
    padding: 2rem;
  }
`;

const MainContent = styled.div`
  flex-grow: 1;
  padding-left: 1rem;
  width: 100%;
  overflow-x: hidden;

  @media (min-width: 769px) {
    padding-left: 2rem;
    max-width: calc(100% - 150px);
    margin-left: auto;
    margin-right: auto;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  width: 100%;

  @media (min-width: 769px) {
    flex-direction: row;
  }
`;

const ContactBoxContainer = styled(motion.div)`
  width: 100%; 
  padding-right: 1rem;

  @media (max-width: 768px) {
    padding-right: 0;
    margin-bottom: 1rem;
  }
`;

const ContactDetailsContainer = styled(motion.div)`
  width: 100%;
  overflow: hidden;

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: #002C2C;
  }

  @media (min-width: 769px) {
    padding-left: 1rem;
  }
`;

const SettingsContainerWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  overflow-x: hidden;
`;

const AdminPage: React.FC = () => {
  const { decodedToken } = useUserSettings();
  const role = decodedToken?.role;
  
  const [selectedOption, setSelectedOption] = useState<string>('Settings');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const { users, isLoadingUsers, loadUsers, handleRefreshUsers } = useUserManagement();
  //useContacts there is a manager variable we could use too if we wanted to see associated managers
  //we can also use error: contactsError
  const { clients, isLoading: isLoadingContacts, fetchContacts } = useContacts(true);

  useEffect(() => {
    console.log('Role:', role);
    if (role && (role === 'admin' || role === 'manager')) {
      console.log('Fetching contacts...');
      fetchContacts();
    } else if (role === 'client') {
      console.log('Not fetching contacts. Role is client.');
    } else {
      console.log('Role not yet available or insufficient permissions.');
    }
  }, [role, fetchContacts]);

  useEffect(() => {
    loadUsers();
  }, [role, loadUsers]);

  const handleUserSelect = (user: User) => {
    setSelectedUser(user);
  };

  const handleCall = () => {
    // Implement call functionality
    console.log('Calling user:', selectedUser?.displayName);
  };

  const handleCloseContactDetails = () => {
    setSelectedUser(null);
  };

  const handleDelete = () => {
    setSelectedUser(null);
    handleRefreshUsers();
  };

  return (
    <AdminPageContainer>
      <Sidebar
        selectedOption={selectedOption}
        onOptionSelect={setSelectedOption}
        userRole={role}
      />
      <MainContent>
      {selectedOption === 'All Users' && role === 'admin' && (
          <ContentContainer>
            <ContactBoxContainer
              initial={{ flex: 2 }}
              animate={{ flex: selectedUser ? 1 : 2 }}
              transition={{ duration: 0.3 }}
            >
              <ContactBox 
                //change for brain transfer
                users={users}
                onUserSelect={handleUserSelect}
                isLoading={isLoadingUsers}
              />
            </ContactBoxContainer>
            <AnimatePresence mode="wait">
              {selectedUser && (
                <ContactDetailsContainer
                  key={selectedUser.uid}
                  initial={window.innerWidth <= 768 ? { x: '100%' } : { width: 0, opacity: 0 }}
                  animate={window.innerWidth <= 768 ? { x: 0 } : { width: '50%', opacity: 1 }}
                  exit={window.innerWidth <= 768 ? { x: '100%' } : { width: 0, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <ContactDetails 
                    user={selectedUser} 
                    onCall={handleCall}
                    onClose={handleCloseContactDetails}
                    onDelete={handleDelete}
                  />
                </ContactDetailsContainer>
              )}
            </AnimatePresence>
          </ContentContainer>
        )}
      {selectedOption === 'Users' && role !== 'client' && (
          <ContentContainer>
            <ContactBoxContainer
              initial={{ flex: 2 }}
              animate={{ flex: selectedUser ? 1 : 2 }}
              transition={{ duration: 0.3 }}
            >
              <ContactBox 
                //change for brain transfer
                users={clients.map(contact => ({
                  uid: contact.id,
                  displayName: contact.name,
                  email: contact.email,
                  role: contact.role,
                  phone: 0, // You might want to add this to your Contact interface if needed
                  securityKeysCount: 0 // You might want to add this to your Contact interface if needed
                }))}
                onUserSelect={handleUserSelect}
                isLoading={isLoadingContacts}
              />
            </ContactBoxContainer>
            <AnimatePresence mode="wait">
              {selectedUser && (
                <ContactDetailsContainer
                  key={selectedUser.uid}
                  initial={window.innerWidth <= 768 ? { x: '100%' } : { width: 0, opacity: 0 }}
                  animate={window.innerWidth <= 768 ? { x: 0 } : { width: '50%', opacity: 1 }}
                  exit={window.innerWidth <= 768 ? { x: '100%' } : { width: 0, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <ContactDetails 
                    user={selectedUser} 
                    onCall={handleCall}
                    onClose={handleCloseContactDetails}
                    onDelete={handleDelete}
                  />
                </ContactDetailsContainer>
              )}
            </AnimatePresence>
          </ContentContainer>
        )}
        {selectedOption === 'Settings' && (
          <ContentContainer>
            <SettingsContainerWrapper>
              <SettingsContainer />
            </SettingsContainerWrapper>
          </ContentContainer>
        )}
        {selectedOption === 'Reports' && (
          <ContentContainer>
            <div>Reports are a pending feature. Please come back soon.</div>
          </ContentContainer>
        )}
      </MainContent>
    </AdminPageContainer>
  );
};

export default AdminPage;