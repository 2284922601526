/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
import React, { useContext, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../context/AuthProvider';

const RequireAuth: React.FC = () => {
  const { user } = useContext(AuthContext); // Get user from AuthContext
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);

  return user ? <Outlet /> : null;
};

export default RequireAuth;