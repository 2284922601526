/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Button, Row, Col, Card, Alert } from 'react-bootstrap';
import { Login } from '../hooks/Login';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../configuration/FirebaseConfig';
import { useNavigate } from 'react-router-dom';

export default function LoginPage() {
  const { handleSubmit, error } = Login();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        //if user succesfully logs on we navigate them to our admin page
        navigate('/home');
      } else {
        return () => unsubscribe();
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  return (
    <Container>
      <Row>
        <Col sm={2}></Col>
        <Col>
          <br />
          <Container>
            <Row>
              <Col sm={2}></Col>
              <Col>
                <Container>
                  <Card>
                    <Card.Body>
                      <Card.Title>Click below to Authenticate</Card.Title>
                      {error && <Alert variant="danger">{error}</Alert>}
                      <Button
                        variant="outline-primary"
                        onClick={(e) => handleSubmit(e)}
                        className="custom-button w-100"
                      >
                        Sign In
                      </Button>
                    </Card.Body>
                  </Card>
                </Container>
              </Col>
              <Col sm={2}></Col>
            </Row>
          </Container>
        </Col>
        <Col sm={2}></Col>
      </Row>
    </Container>
  );
}
