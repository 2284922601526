import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { ROLE_ADMIN, ROLE_MANAGER, ROLE_CLIENT } from '../constants/Roles';

interface UserRegistrationFormProps {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  setEmail: (value: string) => void;
  setRole: (value: string) => void;
  showUsername?: boolean;
  username?: string;
  setUsername?: (value: string) => void;
}

const UserRegistrationForm: React.FC<UserRegistrationFormProps> = ({
  firstName, lastName, email, role, 
  setFirstName, setLastName, setEmail, setRole,
  showUsername = false, username = '', setUsername = () => {}
}) => {
  return (
    <>
      {showUsername && (
        <Form.Group className="mb-3" controlId="username">
          <Form.Label>Username:</Form.Label>
          <Form.Control
            type="text"
            placeholder='Username'
            onChange={(e) => setUsername(e.target.value)}
            value={username}
            required
          />
        </Form.Group>
      )}
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="firstName">
            <Form.Label>First Name:</Form.Label>
            <Form.Control
              type="text"
              placeholder='First Name'
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              required
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="lastName">
            <Form.Label>Last Name:</Form.Label>
            <Form.Control
              type="text"
              placeholder='Last Name'
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email Address:</Form.Label>
        <Form.Control
          type="email"
          placeholder='Email Address'
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formRole">
        <Form.Label>Role:</Form.Label>
        <Form.Control
          as="select"
          value={role}
          onChange={(e) => setRole(e.target.value)}
          required
        >
          <option value={ROLE_CLIENT}>Client</option>
          <option value={ROLE_MANAGER}>Wealth Manager</option>
          <option value={ROLE_ADMIN}>Admin</option>
        </Form.Control>
      </Form.Group>
    </>
  );
};

export default UserRegistrationForm;
