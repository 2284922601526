import React, { useEffect, useState, useRef } from 'react';
import {
  CallingState,
  StreamCall,
  StreamVideo,
  StreamVideoClient,
} from '@stream-io/video-react-sdk';
import './App.css';
import '@stream-io/video-react-sdk/dist/css/styles.css';
import '../style.css';
import { auth } from '../configuration/FirebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import { tokenProvider, createClient } from '../components/ClientAndTokenProvider';
import { MyCallUILayout } from '../components/MyCallUILayout';

//bootstrap imports
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

interface JoinViewProps {
  contextUserId?: string;
  ringCallId?: string;
}

export const JoinView: React.FC<JoinViewProps> = ({ contextUserId, ringCallId }) => {
  const [auth_user] = useAuthState(auth);
  const [userId, setUserId] = useState<string>(auth_user ? auth_user.uid : '');
  const [callId, setCallId] = useState<string>(ringCallId || '');
  const [call, setCall] = useState<any>();
  const [callingState, setCallingState] = useState<CallingState>(CallingState.IDLE);
  const [userKicked, setUserKicked] = useState(false);
  const [client, setClient] = useState<StreamVideoClient>();
  const [isLoading, setIsLoading] = useState(true); // New loading state
  const isJoining = useRef(false); // Ref to track the joining state

  const apiKey = process.env.REACT_APP_GETSTREAM_API_KEY;

  if (!apiKey) {
    throw new Error('REACT_APP_GETSTREAM_API_KEY is not defined');
  }

  useEffect(() => {
    if (!userId) {
      toast.error('User ID is required', { toastId: 'user-id-required' });
      return;
    }

    if (!callId) {
      setCallId(Math.random().toString(36).substring(2, 8));
    }

    const handleSubmit = async () => {
      if (isJoining.current) return;
      isJoining.current = true;

      try {
        let user_display_name = auth_user?.displayName || 'Unknown User';
        const user = {
          id: userId,
          name: user_display_name,
          image: `https://getstream.io/random_svg/?id=${user_display_name}&name=${user_display_name}`,
        };

        const token = await tokenProvider(user);

        if (!token || token.trim() === '') {
          console.log('Token is empty');
          alert('Token is empty. Please check your token provider.');
          return;
        }

        const client = await createClient(apiKey, user, token);

        const callType = 'default';
        const call = client.call(callType, callId);
        await call.join({ create: true });

        if (!client) {
          console.error('Client is undefined');
          alert('Failed to initialize client. Check the console for more details.');
          return;
        }

        await call.camera.enable();
        await call.microphone.enable();

        setUserId(user.id);
        setClient(client);
        setCall(call);
        setCallingState(CallingState.JOINED);

        document.body.classList.add('joined-call');
        console.log('Joined call:', callId);

      } catch (error) {
        console.error(error);
        if (typeof error === 'object' && error !== null && 'message' in error) {
          const message = (error as { message: string }).message;
          if (message.includes('credential invalidated')) {
            setUserKicked(true);
          }
        }
        alert('Failed to join/create call. Check the console for more details.');
      } finally {
        isJoining.current = false;
        setIsLoading(false)
      }
    };
    // Ensure handleSubmit is called only once
    if (!isJoining.current) {
      handleSubmit();
    }
  }, [userId, callId, apiKey, auth_user]);

  return (
    <Container>
      <Row>
        <br></br>
      </Row>
      <Row>
        <div className="App">
          {isLoading ? (
            <div className="callContainer">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : client && (
            <div className='callContainer'>
              <StreamVideo client={client}>
                <div className="App">
                  {userKicked && (
                    <div className="kicked-message">
                      You were kicked!
                    </div>
                  )}
                  {callingState === CallingState.JOINED && call && (
                    <StreamCall call={call}>
                      <MyCallUILayout />
                    </StreamCall>
                  )}
                </div>
              </StreamVideo>
            </div>
          )}
        </div>
      </Row>
    </Container>
  );
};

export default JoinView;
