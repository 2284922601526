import React, { useState } from 'react';
import styled from 'styled-components';
import UserEntry from './UserEntry';

// Export the User type
export interface User {
  uid: string;
  phone: number;
  role: string;
  securityKeysCount: number;
  displayName: string;
  email: string;
}

export interface UserListProps {
  users: (User | null)[];
  showEmail?: boolean;
  isLoading?: boolean;
  onUserClick?: (user: User) => void;
}

const ListContainer = styled.div`
  font-family: 'Inter', sans-serif;
  max-height: 80vh;
  overflow-y: auto;
  border: 1px solid #D8EAD9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const EmptyListMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.5em;
  color: #D8EAD9;
  font-weight: 600;
`;


const UserList: React.FC<UserListProps> = ({ users, showEmail = true, isLoading = false, onUserClick }) => {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  const handleUserClick = (user: User) => {
    setSelectedUserId(prevId => user.uid === prevId ? null : user.uid);
    if (onUserClick) onUserClick(user);
  };

  return (
    <ListContainer>
      {users.length > 0 ? (
        users.map((user, index) => (
          <UserEntry 
            key={user?.uid || `loading-${index}`}
            user={user}
            showEmail={showEmail} 
            $isSelected={user?.uid === selectedUserId}
            onClick={() => user && handleUserClick(user)}
            isLoading={isLoading && !user}
          />
        ))
      ) : (
        <EmptyListMessage>No users to display</EmptyListMessage>
      )}
    </ListContainer>
  );
};

export default UserList;