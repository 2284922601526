import React, { useState } from 'react';
import styled from 'styled-components';
import WaveButton from './WaveButton';
import { Mail, MessageSquare, Share2 } from 'react-feather';
import NewCallAlert from './NewCallAlert';

const CreateCallLinkContainer = styled.div`
  border: 1px solid #B8D8BC;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  color: #D8EAD9;
  font-family: 'Inter', sans-serif;

  min-width: 300px;
  min-height: 200px;
`;

const Header = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #D8EAD9;
`;

const FieldForm = styled.select`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  background-color: #002C2C;
  color: #D8EAD9;
  border: 1px solid #B8D8BC;
  border-radius: 4px;
  font-size: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;



interface CreateCallLinkProps {
  onSend?: (method: string) => void;
}


const CreateCallLink: React.FC<CreateCallLinkProps> = ({ onSend }) => {
const [showNewCallAlert, setShowNewCallAlert] = useState(false);
  const [callMethod, setCallMethod] = useState('');
  const [callType, setCallType] = useState('video');

  const handleSend = (method: string) => {
    console.log(`Send via ${method}`);
    setCallMethod(method);
    setShowNewCallAlert(true);
    onSend?.(method);
  };

  return (
    <CreateCallLinkContainer>
      <Header>Create Call Link</Header>
      <FieldForm 
        value={callType} 
        onChange={(e) => setCallType(e.target.value)}
      >
        <option value="video">Video</option>
        <option value="voice">Voice</option>
      </FieldForm>
      <ButtonContainer>
        <WaveButton 
          onClick={() => handleSend('email')} 
          label="Send via email"
          icon={<Mail size={18} />}
        />
        <WaveButton 
          onClick={() => handleSend('sms')} 
          label="Send via SMS"
          icon={<MessageSquare size={18} />}
        />
        <WaveButton 
          onClick={() => handleSend('whatsapp')} 
          label="Send via WhatsApp"
          icon={<Share2 size={18} />}
        />
      </ButtonContainer>
      {showNewCallAlert && (
        <NewCallAlert
          bodyText={`Auto-send via ${callMethod} is still in development. Please copy the link and send it manually.`}
          isVideoCall={callType}
          onClose={() => setShowNewCallAlert(false)}
        />
      )}
    </CreateCallLinkContainer>
  );
};

export default CreateCallLink;
