import { useState } from 'react';
import { toast } from 'react-toastify';
import WaveServer from '../services/WaveServer';
import { auth } from '../configuration/FirebaseConfig';

export const useUserAssociation = () => {
  // const [managerUID, setManagerUID] = useState('');
  // const [clientUID, setClientUID] = useState('');
  const [isAssociating, setIsAssociating] = useState(false);

  const handleAssociateUsers = async (managerUID: string, clientUID: string) => {
    setIsAssociating(true);
    console.log('associating users', managerUID, clientUID);
    try {
      const idToken = await auth.currentUser?.getIdToken();
      if (!idToken) {
        throw new Error('No ID token available');
      }
      const result = await WaveServer.associateUsers(managerUID, clientUID, idToken);
      if (result.success) {
        toast.success('Users associated successfully');
        // setManagerUID('');
        // setClientUID('');
      } else {
        toast.error(`Failed to associate users: ${result.message}`);
      }
    } catch (error) {
      console.error('Error associating users:', error);
      toast.error(error instanceof Error ? error.message : 'An unknown error occurred while associating users');
    } finally {
      setIsAssociating(false);
    }
  };

  return { isAssociating, handleAssociateUsers };
};

export default useUserAssociation;