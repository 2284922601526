import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Passwordless from '@passwordlessdev/passwordless-client';
import { PASSWORDLESS_API_KEY } from '../configuration/PasswordlessOptions';
import WaveServer from '../services/WaveServer';
import { getAuth } from 'firebase/auth';

interface RegisterSecurityKeyProps {
  userId: string;
  userEmail: string;
  onSuccess: () => void;
  onCancel: () => void;
}

const RegisterSecurityKey: React.FC<RegisterSecurityKeyProps> = ({ userId, userEmail, onSuccess, onCancel }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alias, setAlias] = useState('');

  const handleRegisterKey = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!alias.trim()) {
      toast.error('Please enter an alias for the security key.', { toastId: 'alias-missing' });
      setIsSubmitting(false);
      return;
    }

    if (alias.length > 250) {
      toast.error('Alias must be no more than 250 characters.', { toastId: 'alias-too-long' });
      setIsSubmitting(false);
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }

      const idToken = await user.getIdToken();
      
      const registerToken = await WaveServer.registerNewKey(userId, userEmail, idToken);
      if (typeof registerToken !== 'string' || !registerToken.startsWith('register_')) {
        throw new Error('Invalid registration token received');
      }

      const passwordless_client = new Passwordless.Client({
        apiKey: PASSWORDLESS_API_KEY ?? '',
      });

      const finalResponse = await passwordless_client.register(registerToken, alias);
      if (!finalResponse) {
        throw new Error('Failed to register security key');
      }

      toast.success(`Security key "${alias}" registered successfully!`, { toastId: 'registration-success' });
      onSuccess();
    } catch (error: any) {
      toast.error(`Registration failed: ${error.message}`, {
        className: 'toast-error',
        toastId: 'registration-error'
      });
      console.error('Error during registration:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={true} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Register New Security Key</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleRegisterKey}>
          <Form.Group className="mb-3">
            <Form.Label>Key Alias</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter an alias for your security key"
              value={alias}
              onChange={(e) => setAlias(e.target.value)}
              maxLength={250}
              required
            />
            <Form.Text className="text-muted">
              Alias must be unique and no more than 250 characters.
            </Form.Text>
          </Form.Group>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Registering...' : 'Register Security Key'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RegisterSecurityKey;
