// errorLogger.ts
import LogRocket from 'logrocket';

export const logError = (error: Error, context?: any) => {
    const sanitizedError = {
        message: error.message,
        stack: error.stack,
        // Add any other non-sensitive error properties
    };

    const sanitizedContext = context ? JSON.parse(JSON.stringify(context)) : {};

    // Remove sensitive information
    delete sanitizedContext.user;
    delete sanitizedContext.token;
    // Add more fields to remove as needed

    // Log to console in development
    if (process.env.NODE_ENV === 'development') {
        console.error('Error:', sanitizedError, 'Context:', sanitizedContext);
    } else {
        LogRocket.captureException(error, {
            extra: sanitizedContext
        });
    }

    // You can add more logging services here (e.g., Sentry, custom backend API)
};
