import React from 'react';
import styled from 'styled-components';
import { User } from './UserEntry';

const ProfileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const Avatar = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #D4EBD8;
  color: #0E2B2C;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const UserName = styled.h1`
  font-size: 2rem;
  margin-bottom: 0.5rem;
  text-align: center;
`;

const ContactInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

const InfoItem = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: .5rem;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 2rem;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  margin-left: 2rem;
`;

const CenterColumn = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
    width: 1px;
    background-color: #B8D8BC;
    margin: 0 2rem;
  }
`;


const InfoLabel = styled.span`
  flex: 0 0 80px; // Adjust this width as needed
  font-weight: bold;
`;

const InfoValue = styled.span`
  flex: 1;
`;

interface UserProfileProps {
  user: User;
}

const UserProfile: React.FC<UserProfileProps> = ({ user }) => {
  const displayName = user.displayName || 'Unknown User';
  const email = user.email || 'No email provided';
  const firstLetter = displayName.charAt(0).toUpperCase();
  const phoneNumber = user.phone ? user.phone.toString() : 'N/A';

  return (
    <ProfileContainer>
        <ColumnContainer>
      <LeftColumn>
        <Avatar>{firstLetter}</Avatar>
        <UserName>{displayName}</UserName>
      </LeftColumn>
      <CenterColumn></CenterColumn>
        <RightColumn>
          <ContactInfo>
            <InfoItem>
              <InfoLabel>Email:</InfoLabel>
              <InfoValue>{email}</InfoValue>
            </InfoItem>
            <InfoItem>
              <InfoLabel>Phone:</InfoLabel>
              <InfoValue>{phoneNumber}</InfoValue>
            </InfoItem>
            <InfoItem>
              <InfoLabel>Keys:</InfoLabel>
              <InfoValue>{user.securityKeysCount}</InfoValue>
            </InfoItem>
            <InfoItem>
              <InfoLabel>Role:</InfoLabel>
              <InfoValue>{user.role}</InfoValue>
            </InfoItem>
            <InfoItem>
              <InfoLabel>UID:</InfoLabel>
              <InfoValue>{user.uid}</InfoValue>
            </InfoItem>
          </ContactInfo>
        </RightColumn>
      </ColumnContainer>
    </ProfileContainer>
  );
};

export default UserProfile;
