/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
import WaveServer from '../services/WaveServer';
import React, { useEffect, useRef, useState } from 'react';
import * as Passwordless from '@passwordlessdev/passwordless-client';
import { PASSWORDLESS_API_KEY } from '../configuration/PasswordlessOptions';
import { toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Form, Button, Row, Col, Card } from 'react-bootstrap';
import { ROLE_CLIENT } from '../constants/Roles';
import UserRegistrationForm from '../components/UserRegistrationForm';

const RegistrationPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [adminPassword, setAdminPassword] = useState('');

  const userRef = useRef<HTMLInputElement>(null);
  const errRef = useRef<HTMLParagraphElement>(null);
  const [user, setUser] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [role, setRole] = useState(ROLE_CLIENT);

  useEffect(() => {
    if (userRef.current) {
      userRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [user]);

  const handleAdminAuth = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      // Request challenge
      const challenge = await WaveServer.getAuthChallenge();

      // Combine password and challenge, then hash
      const encoder = new TextEncoder();
      const data = encoder.encode(adminPassword + challenge);
      const hashBuffer = await crypto.subtle.digest('SHA-256', data);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

      // Send response
      const isAuthenticated = await WaveServer.verifyAdminPassword(hashHex, challenge);
      if (isAuthenticated) {
        setIsAuthenticated(true);
      } else {
        toast.error('Invalid password');
      }
    } catch (error) {
      console.error('Error verifying admin password:', error);
      toast.error('Error verifying password');
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!PASSWORDLESS_API_KEY) {
      throw new Error('PASSWORDLESS_API_KEY is undefined');
    }

    let registerToken = null;
    try {
      const response = await WaveServer.register(user, firstName, lastName, email, role);
      registerToken = response.token;
    } catch (error) {
      if (error instanceof Error) {
        toast(error.message, { className: 'toast-error' });
      }
      if (!registerToken) {
        toast(`Try using a new email address.`);
        toast(`Is the WaveServer Running? No Token provided!`);
      }
    }

    if (typeof registerToken === 'string' && registerToken.startsWith('register_')) {
      const passwordless_client = new Passwordless.Client({
        apiKey: PASSWORDLESS_API_KEY,
      });
      try {
        const finalResponse = await passwordless_client.register(registerToken, "first_one");

        if (finalResponse && finalResponse.error) {
          throw new Error(finalResponse.error.title);
        }

        if (finalResponse) {
          toast(`Registered '${email}'!`);
          console.log(finalResponse);
          console.log(`User '${email}' was registered successfully.`);
          setIsRegistered(true);
        }
      } catch (error) {
        if (error instanceof Error) {
          toast(`Registration failed: ${error.message}`, { className: 'toast-error' });
        }
      }
    } else {
      console.log('Registration failed');
    }
    setIsSubmitting(false);
  }

  let content;
  if (!isAuthenticated) {
    content = (
      <Container>
        <Card>
          <Card.Body>
            <Form onSubmit={handleAdminAuth}>
              <Form.Group controlId="adminPassword">
                <Form.Label>Admin Password:</Form.Label>
                <Form.Control
                  type="password"
                  value={adminPassword}
                  onChange={(e) => setAdminPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Button variant="outline-primary" type="submit" className="mt-3">
                Authenticate
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
  } else if (isRegistered) {
    content = (
      <Container>
        <Card>
          <Card.Body>
            <Card.Title>Thank you for registering!</Card.Title>
            <Card.Text>
              Welcome to Wavesign! If this is your first time here please wait for a call link to be provided. Otherwise, feel free to navigate to the login page!
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    );
  } else {
    content = (
      <Container>
        <Row>
          <Col sm={2}></Col>
          <Col>
            <p ref={errRef} className={errMsg ? 'errmsg' : 'offscreen'} aria-live="assertive">
              {errMsg}
            </p>
            <Container>
              <Card>
                <Card.Body>
                  <Form onSubmit={handleSubmit}>
                    <UserRegistrationForm
                      firstName={firstName}
                      lastName={lastName}
                      email={email}
                      role={role}
                      setFirstName={setFirstName}
                      setLastName={setLastName}
                      setEmail={setEmail}
                      setRole={setRole}
                      showUsername={true}
                      username={user}
                      setUsername={setUser}
                    />
                    <Button variant="outline-primary" type="submit" disabled={isSubmitting} className="custom-button w-100">
                      Register
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
              <br />
            </Container>
          </Col>
          <Col sm={2}></Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col sm={2}></Col>
        <Col>
          <br />
          {content}
        </Col>
        <Col sm={2}></Col>
      </Row>
    </Container>
  );
}

export default RegistrationPage;