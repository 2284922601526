/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
// from https://github.com/bitwarden/passwordless-react-example/blob/main/src/components/Layout.jsx

import Menu from './Menue';
import { Outlet } from 'react-router-dom';
import classes from './Layout.module.css';

function Layout(props: React.PropsWithChildren<{}>) {
    return (
        <div>
            <Menu />
            <main className={classes.main}>
                <Outlet />
            </main>
        </div>
    );
}

export default Layout;