import React, { useState, ReactNode } from 'react';
import styled from 'styled-components';

interface ButtonContainerProps {
  $isActive: boolean;
}

const ButtonContainer = styled.button<ButtonContainerProps>`
  background-color: ${props => props.$isActive ? '#B8D8BC' : '#D8EAD9'};
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: background-color 0.3s ease-in-out;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-family: 'Inter', sans-serif;
  opacity: ${props => props.disabled ? 0.5 : 1};

  &:hover {
    background-color: ${props => !props.disabled && '#B8D8BC'};
  }
`;

const IconWrapper = styled.span`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

const LabelWrapper = styled.span`
  display: inline-flex;
  align-items: center;
`;

export interface WaveButtonProps {
    onClick?: () => void;
    label: string;
    icon?: ReactNode;
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
  }
  
const WaveButton: React.FC<WaveButtonProps> = ({ onClick, label, icon, type = 'button', disabled = false }) => {
    const [isActive, setIsActive] = useState(false);
  
    const handleButtonClick = () => {
      if (!disabled && onClick) {
        onClick();
        setIsActive(true);
        setTimeout(() => setIsActive(false), 300);
      }
    };
  
    return (
      <ButtonContainer
        type={type}
        onClick={handleButtonClick}
        $isActive={isActive}
        disabled={disabled}
      >
        {icon && <IconWrapper>{icon}</IconWrapper>}
        <LabelWrapper>{label}</LabelWrapper>
      </ButtonContainer>
    );
  };
  
  export default WaveButton;
