/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
import { HttpStatusCode } from 'axios';

const WaveServer = {
    signIn: async (token: string) => {
        const REACT_APP_WAVESERVER_URL = process.env.REACT_APP_WAVESERVER_URL;
        if (!REACT_APP_WAVESERVER_URL) {
            throw new Error('REACT_APP_WAVESERVER_URL is not defined');
        }
        const body = {
            email: "",
            userId: "",
            token: token
        };
        const response = await fetch(REACT_APP_WAVESERVER_URL + '/signin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        });

        if (!response.ok) {
            response.text().then(data => {
                console.log('Response:', data);
            }).catch(error => {
                console.log('Error reading text:', error);
            });
            throw new Error(`WaveServer HTTP error! status: ${response.status}`);
        }

        const json = await response.json();
        return json;
    },

    register: async (user: string, firstName: string, lastName: string, email: string, role: string) => {
        const REACT_APP_WAVESERVER_URL = process.env.REACT_APP_WAVESERVER_URL;
        if (!REACT_APP_WAVESERVER_URL) {
            throw new Error('REACT_APP_WAVESERVER_URL is not defined');
        }
        const body = {
            username: user,
            firstName: firstName,
            lastName: lastName,
            email: email,
            role: role
        };
        const response = await fetch(REACT_APP_WAVESERVER_URL + '/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const json = await response.json();
        return json;
    },

    token: async (user: { id: string; name: string; image: string; }, idToken: string): Promise<string> => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
            },
            body: JSON.stringify({
                "id": user.id,
                "name": user.name,
            }),
        };

        try {
            const response = await fetch(process.env.REACT_APP_WAVESERVER_URL + '/token', requestOptions);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            if (!data.token || data.token.trim() === '') {
                console.log('Token is empty');
                alert('Token is empty. Please check your WaveServer token provider.');
                return "";
            }

            return data.token;
        } catch (error) {
            console.error('Network error:', error);
            return ""; // Return an empty string if an error occurs
        }
    },

    registerNewKey: async (uid: string, username: string, idToken: string): Promise<string> => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
            },
            body: JSON.stringify({
                "uid": uid,
                "username": username,
            }),
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_WAVESERVER_URL}/register/security-key`, requestOptions);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            if (!data.token || data.token.trim() === '') {
                console.log('Token is empty');
                alert('Token is empty. Please check your WaveServer token provider.');
                return "";
            }

            return data.token;
        } catch (error) {
            console.error('Network error:', error);
            return ""; // Return an empty string if an error occurs
        }
    },

    sendMagicLink: async (username: string, firstName: string, lastName: string, email: string, role: string) => {
        const REACT_APP_WAVESERVER_URL = process.env.REACT_APP_WAVESERVER_URL;
        if (!REACT_APP_WAVESERVER_URL) {
            throw new Error('REACT_APP_WAVESERVER_URL is not defined');
        }
        const body = {
            username: username,
            firstName: firstName,
            lastName: lastName,
            email: email
        };

        const response = await fetch(REACT_APP_WAVESERVER_URL + '/register/magiclink', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        });

        if (!response.ok) {
            if (response.status === 204) {
                return { success: false, message: 'No content returned from server' };
            }
            if (response.status === 409) {
                return { success: false, message: 'User already exists, please login' };
            }
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return { success: true, message: 'Magic link sent successfully'};
    },

    listCredentials: async (uid: string, idToken: string): Promise<any> => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
            }
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_WAVESERVER_URL}/credentials/list?uid=${uid}`, requestOptions);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Network error:', error);
            throw error;
        }
    },

    deleteCredential: async (credentialId: string, userId: string, idToken: string): Promise<void> => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
            },
            body: JSON.stringify({ credentialId, userId })
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_WAVESERVER_URL}/credentials/delete`, requestOptions);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('Network error:', error);
            throw error;
        }
    },

    manageUserRole: async (uid: string, role: string, idToken: string): Promise<{ success: boolean; message: string }> => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
            },
            body: JSON.stringify({ uid, role })
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_WAVESERVER_URL}/users/manage-user-role`, requestOptions);
            if (!response.ok) {
                const errorData = await response.json();
                return { success: false, message: errorData.message || `HTTP error! status: ${response.status}` };
            }
            return { success: true, message: 'User role updated successfully' };
        } catch (error) {
            console.error('Error managing user role:', error);
            return { success: false, message: error instanceof Error ? error.message : 'An unknown error occurred' };
        }
    },

    getAllUsers: async (idToken: string, pageSize: number = 50, pageToken: string = ''): Promise<{ success: boolean; users?: any[]; nextPageToken?: string; message?: string }> => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
            }
        };

        try {
            const url = new URL(`${process.env.REACT_APP_WAVESERVER_URL}/users/all`);
            url.searchParams.append('pageSize', pageSize.toString());
            if (pageToken) {
                url.searchParams.append('pageToken', pageToken);
            }

            const response = await fetch(url.toString(), requestOptions);
            if (!response.ok) {
                if (response.status === 403) {
                    return { success: false, message: 'Unauthorized: Admin access required' };
                }
                const errorData = await response.json();
                return { success: false, message: errorData.message || `HTTP error! status: ${response.status}` };
            }
            const data = await response.json();
            return { 
                success: true, 
                users: data.users,
                nextPageToken: data.nextPageToken
            };
        } catch (error) {
            console.error('Error fetching all users:', error);
            return { success: false, message: error instanceof Error ? error.message : 'An unknown error occurred' };
        }
    },

    deleteUser: async (uid: string, idToken: string): Promise<{ success: boolean; message?: string }> => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
            },
            body: JSON.stringify({ uid })
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_WAVESERVER_URL}/users/delete`, requestOptions);
            if (!response.ok) {
                const errorData = await response.json();
                return { success: false, message: errorData.message || `HTTP error! status: ${response.status}` };
            }
            return { success: true, message: 'User deleted successfully' };
        } catch (error) {
            console.error('Error deleting user:', error);
            return { success: false, message: error instanceof Error ? error.message : 'An unknown error occurred' };
        }
    },

    getAuthChallenge: async (): Promise<string> => {
        const REACT_APP_WAVESERVER_URL = process.env.REACT_APP_WAVESERVER_URL;
        if (!REACT_APP_WAVESERVER_URL) {
            throw new Error('REACT_APP_WAVESERVER_URL is not defined');
        }
        
        try {
            const response = await fetch(`${REACT_APP_WAVESERVER_URL}/signin/get-auth-challenge`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            return data.challenge;
        } catch (error) {
            console.error('Error getting auth challenge:', error);
            throw error;
        }
    },

    verifyAdminPassword: async (response: string, challenge: string): Promise<boolean> => {
        const REACT_APP_WAVESERVER_URL = process.env.REACT_APP_WAVESERVER_URL;
        if (!REACT_APP_WAVESERVER_URL) {
            throw new Error('REACT_APP_WAVESERVER_URL is not defined');
        }
        
        try {
            const serverResponse = await fetch(`${REACT_APP_WAVESERVER_URL}/signin/verify-admin-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ response, challenge }),
            });

            if (!serverResponse.ok) {
                return false;
            }

            const result = await serverResponse.json();
            return result.success;
        } catch (error) {
            console.error('Error verifying admin password:', error);
            return false;
        }
    },

    associateUsers: async (managerUID: string, clientUID: string, idToken: string): Promise<{ success: boolean; message: string }> => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
            },
            body: JSON.stringify({ managerUID, clientUID })
        };
    
        try {
            const response = await fetch(`${process.env.REACT_APP_WAVESERVER_URL}/users/associate`, requestOptions);
            if (!response.ok) {
                const errorData = await response.json();
                return { success: false, message: errorData.message || `HTTP error! status: ${response.status}` };
            }
            return { success: true, message: 'Users associated successfully' };
        } catch (error) {
            console.error('Error associating users:', error);
            return { success: false, message: error instanceof Error ? error.message : 'An unknown error occurred' };
        }
    },
    
    getUserAssociations: async (uid: string, idToken: string): Promise<{ success: boolean; data?: any; message: string }> => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_WAVESERVER_URL}/users/associations?uid=${uid}`, requestOptions);
            
            switch (response.status) {
                case HttpStatusCode.Ok: {
                    const data = await response.json();
                    return { success: true, data, message: 'User associations retrieved successfully' };
                }
                case HttpStatusCode.Forbidden: {
                    return { success: false, message: 'Unauthorized access' };
                }
                case HttpStatusCode.NotFound: {
                    return { success: false, message: 'User associations not found' };
                }
                default: {
                    const errorData = await response.json();
                    return { success: false, message: errorData.message || `HTTP error! status: ${response.status}` };
                }
            }
        } catch (error) {
            console.error('Error getting user associations:', error);
            return { success: false, message: error instanceof Error ? error.message : 'An unknown error occurred' };
        }
    },

    getUsersInfo: async (userIds: string[], idToken: string): Promise<{ success: boolean; data?: any; message: string }> => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
            },
            body: JSON.stringify({ userIds })
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_WAVESERVER_URL}/users/info`, requestOptions);
            
            if (response.ok) {
                const data = await response.json();
                console.log('WaveServer.getUsersInfo raw response:', data);
                return { success: true, data: data.users, message: 'User information retrieved successfully' };
            } else {
                const errorData = await response.json();
                return { success: false, message: errorData.message || `HTTP error! status: ${response.status}` };
            }
        } catch (error) {
            console.error('Error getting users info:', error);
            return { success: false, message: error instanceof Error ? error.message : 'An unknown error occurred' };
        }
    }
};

export default WaveServer;