import { useState } from 'react';
import { toast } from 'react-toastify';
import WaveServer from '../services/WaveServer';
import { auth } from '../configuration/FirebaseConfig';

const useChangeUserRole = () => {
  const [isChangingRole, setIsChangingRole] = useState(false);

  const changeUserRole = async (userUid: string, newRole: string) => {
    setIsChangingRole(true);
    try {
      const idToken = await auth.currentUser?.getIdToken();
      if (!idToken) {
        throw new Error('No ID token available');
      }
      if (!userUid) {
        throw new Error('User UID is required');
      }
      if (!newRole) {
        throw new Error('New role is required');
      }
      const result = await WaveServer.manageUserRole(userUid, newRole, idToken);
      if (result.success) {
        toast.success(`Role successfully changed to ${newRole} for user ${userUid}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return true;
      } else {
        toast.error(`Failed to change role: ${result.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return false;
      }
    } catch (error) {
      console.error('Error changing user role:', error);
      toast.error(error instanceof Error ? error.message : 'An unknown error occurred while changing user role', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return false;
    } finally {
      setIsChangingRole(false);
    }
  };

  return { isChangingRole, changeUserRole };
};

export default useChangeUserRole;